import React, { useState, useEffect } from 'react';


import parse from 'html-react-parser';


import Footer from "../Footer/Footer";
import "../Pagina.css";
import "../content-styles.css"

import Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
    content_language

} from '../../../Utils';

import Navbar from "../Navbar/Navbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /*faChevronLeft, faFileDownload,*/ faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { language } from '../WEBContent/Language';

//import regulamentoData from './dadosRegulamento';

const Regulamento = () => {

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [data, setData] = useState([]);   

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/53`)
            .then(res => { 
                setData(res.data)
                //console.log(res.data)

            })
            .catch(erro => alert('Erro'))  

    }
    
    useEffect(() => { 
        getInfo()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 



    return (
        <>
            <Navbar mostrar_botao_voltar={true} />
            <section className="margemBody mt-100">           

                {data.map((v, key) => { 
                    return (
                    <div key={key}>
                        <div className="container-fluid bg-color-gray-light">
                            <div className="container py-4 mb-4">
                                <h3 className="titulo-primario mb-0">
                                    {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                </h3>
                            </div>              
                        </div>
                        <div className="container mt-5 ck-content">
                                            
                    
                            <p style={{color:'var(--cinza-escuro)'}}>
                                {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                            </p> 
                                        
                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                            
                            {/*<p className="font-weight-bold w-100">{data.titulo}</p>                           
                            
                            <p>{data.subtitulo}</p>
                            <p>{data.texto}</p>*/}

                            
                            { v.content[0].media.doc.length > 0 ? (
                                <>      
                                    <label className="font-weight-bold">{language[lang].more}</label>
                                    <ul className="list-group m-0">
                                        {
                                            (lang !== 'pt' && v.content['language'] !== undefined && v.content['language'].length > 0 && v.content.language[lang_id] !== undefined && v.content.language[lang_id]['content_files'] !== undefined && v.content.language[lang_id]['content_files'].length > 0) ? 
                                                v.content.language[lang_id].content_files.map((value, key) => {
                                                    return (
                                                        <li className="list-unstyled" key={key}>
                                                            <a className="mr-2" href={value.path + value.source_name} download={value.name} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                {/*language[lang].submission_rules_pdf*/}
                                                                {value.name}
                                                            </a>
                                                        </li>
                                                        
                                                    )
                                                })
                                            :
                                                v.content.content_files.map((value, key) => {
                                                    return (
                                                        <li className="list-unstyled" key={key}>
                                                            <a className="mr-2" href={value.path + value.source_name} download={value.name} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                {/*language[lang].submission_rules_pdf*/}
                                                                {value.name}
                                                            </a>

                                                            {/*<Link to={{pathname: value.path + value.source_name}} target="_blank" download={value.name}>
                                                                <FontAwesomeIcon className="font-weight-small" icon={faFileDownload} fixedWidth />
                                                                Descarregar ficheiro
                                                            </Link>*/}
                                                        </li>
                                                        
                                                    )
                                                })

                                        }
                                    </ul>  
                                </>                    
                            ) : null }                           
                        
                        </div>
                    </div>
                    );
                })}
            
                <Footer />
            </section>
        </>
      
    );

};
export default Regulamento;


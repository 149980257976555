import React, { useState, useEffect } from 'react';

import parse from 'html-react-parser';


import Footer from "../Footer/Footer";
import "../Pagina.css";
import "../content-styles.css"

import Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
    content_language

} from '../../../Utils';

import Navbar from "../Navbar/Navbar";
import Atividade1 from "./Consultoria";
import Atividade2 from "./WebinarCards";
import Atividade3 from "./SeminarCards";
//import Atividade3 from "./Seminario";
import Atividade4 from "./EstudosManuais";
import Atividade5 from "./Resultados";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /*faChevronLeft, faFileDownload,*/ faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { language } from '../WEBContent/Language';

//import regulamentoData from './dadosRegulamento';

const Concurso = (props) => {

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [data, setData] = useState([]);   

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/130`)
            .then(res => { 
                setData(res.data)
                //console.log(res.data)

            })
            .catch(erro => alert('Erro'))  

    }
    
    useEffect(() => { 
        getInfo()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 



    return (
        <>
            <Navbar mostrar_botao_voltar={true} />
            <section className="margemBody mt-100">

                {data.map((v, key) => { 
                    return (
                    <div key={key}>
                        <div className="container-fluid bg-color-gray-light">
                            <div className="container py-4 mb-0 mb-sm-4">
                                <h3 className="titulo-primario mb-0">
                                    {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                </h3>
                            </div>              
                        </div>
                        <div className="container mt-5 ck-content d-none d-md-block">
                    
                            <p style={{color:'var(--cinza-escuro)'}}>
                                {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                            </p> 
                                        
                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}

                            { v.content[0].media.doc.length > 0 ? (
                                <>      
                                    <label className="font-weight-bold mt-3">{language[lang].more}</label>
                                    <ul className="list-group mb-5">
                                        {
                                            (lang !== 'pt' && v.content['language'] !== undefined && v.content['language'].length > 0 && v.content.language[lang_id] !== undefined && v.content.language[lang_id]['content_files'] !== undefined && v.content.language[lang_id]['content_files'].length > 0) ? 
                                                v.content[0].media.doc.map((value, key) => {
                                                   
                                                    return (                                                        
                                                        <li className="list-unstyled" key={key}>
                                                            <a className="mr-2" href={value.link} download={value.name} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                {/* {language[lang].submission_rules_pdf} */}
                                                                {value.name}
                                                            </a>
                                                        </li>
                                                        
                                                    )
                                                })
                                            :
                                                v.content[0].media.doc.map((value, key) => {
                                                    return (
                                                        <li className="list-unstyled" key={key}>
                                                            <a className="mr-2" href={value.link} download={value.name} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                {/* {language[lang].submission_rules_pdf} */}
                                                                {value.name}
                                                            </a>
                                                        </li>
                                                        
                                                    )
                                                })

                                        }
                                    </ul>  
                                </>                    
                            ) : null }        
                        
                        </div>
                    </div>
                    );
                })}

                <section id="activity_tabs" className="activity-tab mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav>
                                    {/*console.log(props.location.state)*/}
                                    <div className="nav nav-tabs nav-fill mt-3 mt-md-4 mt-lg-5" id="nav-tab" role="tablist">
                                        <a className={"nav-item nav-link align-self-end"} id="nav-atividade-1-tab" data-toggle="tab" href="#nav-atividade-1" role="tab" aria-controls="nav-atividade-1" aria-selected="true">
                                            <h5 className="m-1">{language[lang].atividade_1}</h5>
                                            <div  style={{color: 'var(--body)', fontWeight: 400, fontSize: 'small'}}>{language[lang].atividade_1_txt}</div>
                                        </a>
                                        <a className={"nav-item nav-link align-self-end" + (props.location.state !== undefined && props.location.state.active !== undefined && props.location.state.active === 'webinar' ? ' active' : '')} id="nav-atividade-2-tab" data-toggle="tab" href="#nav-atividade-2" role="tab" aria-controls="nav-atividade-2" aria-selected="false">
                                            <h5 className="m-1">{language[lang].atividade_2}</h5>
                                            <div  style={{color: 'var(--body)', fontWeight: 400, fontSize: 'small'}}>{language[lang].atividade_2_txt}</div>
                                        </a>
                                        <a className={"nav-item nav-link align-self-end" + (props.location.state === undefined ? ' active' : (props.location.state !== undefined && props.location.state.active !== undefined && props.location.state.active === 'seminar' ? ' active' : ''))} id="nav-atividade-3-tab" data-toggle="tab" href="#nav-atividade-3" role="tab" aria-controls="nav-atividade-3" aria-selected="false">
                                            <h5 className="m-1">{language[lang].atividade_3}</h5>
                                            <div  style={{color: 'var(--body)', fontWeight: 400, fontSize: 'small'}}>{language[lang].atividade_3_txt}</div>
                                        </a>
                                        <a className="nav-item nav-link align-self-end" id="nav-atividade-4-tab" data-toggle="tab" href="#nav-atividade-4" role="tab" aria-controls="nav-atividade-4" aria-selected="false">
                                            <h5 className="m-1">{language[lang].atividade_4}</h5>
                                            <div  style={{color: 'var(--body)', fontWeight: 400, fontSize: 'small'}}>{language[lang].atividade_4_txt}</div>
                                        </a>
                                        <a className="nav-item nav-link align-self-end" id="nav-atividade-5-tab" data-toggle="tab" href="#nav-atividade-5" role="tab" aria-controls="nav-atividade-5" aria-selected="false">
                                            <h5 className="m-1">{language[lang].atividade_5}</h5>
                                            <div  style={{color: 'var(--body)', fontWeight: 400, fontSize: 'small'}}>{language[lang].atividade_5_txt}</div>
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab-content mt-5" id="nav-tabContent">
                                    <div className={"tab-pane fade"} id="nav-atividade-1" role="tabpanel" aria-labelledby="nav-atividade-1-tab">
                                        <Atividade1 />
                                    </div>
                                    <div className={"tab-pane fade" + (props.location.state !== undefined && props.location.state.active !== undefined && props.location.state.active === 'webinar' ? ' show active' : '')} id="nav-atividade-2" role="tabpanel" aria-labelledby="nav-atividade-2-tab">
                                        <Atividade2 />
                                    </div>
                                    <div className={"tab-pane fade" + (props.location.state === undefined ? ' show active' : (props.location.state !== undefined && props.location.state.active !== undefined && props.location.state.active === 'seminar' ? ' show active' : ''))} id="nav-atividade-3" role="tabpanel" aria-labelledby="nav-atividade-3-tab">
                                        <Atividade3 />
                                    </div>
                                    <div className="tab-pane fade" id="nav-atividade-4" role="tabpanel" aria-labelledby="nav-atividade-4-tab">
                                        <Atividade4 />
                                    </div>
                                    <div className="tab-pane fade" id="nav-atividade-5" role="tabpanel" aria-labelledby="nav-atividade-5-tab">
                                        <Atividade5 />
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </section>
            
                <Footer />
            </section>
        </>
      
    );

};
export default Concurso;


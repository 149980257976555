import React, { useState, useEffect, useRef } from 'react';

import { Button } from "reactstrap";
//import Footer from "../Footer/Footer";


//import Navbar from "../Navbar/Navbar";
import './Forms.css';

import Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
    reqPOST,
    reqPUT,
    reqFILE,
    repo_banner_link,
    content_language

} from '../../../Utils';


import { language } from '../WEBContent/Language';

import uniqid from 'uniqid';

import Swal from 'sweetalert2'

const AppForm = () => {

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [uid,] = useState(uniqid());

    const [files_doc, set_files_doc] = useState([]);
    const [files_doc_name, set_files_doc_name] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    }, []);

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
    }

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,

            type: 'tipo2',

            email: '',
            name_01: '',
            name_02: '',
            name_03: '',
            contact_01: '',
            contact_02: '',
            contact_03: '',
            email_01: '',
            email_02: '',
            email_03: '',
            date_birth_01: '',
            date_birth_02: '',
            date_birth_03: '',
            qualifications_01: '',
            qualifications_02: '',
            qualifications_03: '',
            designation: '',
            location: '',

            option_02: '',
            option_03: '',

            description_01: '',
            description_02: '',
            description_03: '',
            description_04: '',
            description_05: '',
            description_06: '',
            description_07: '',
            description_08: '',
            description_09: '',
            description_10: '',
            description_11: '',

            previsions: [
                {
                    ano1: '',
                    ano2: '',
                    ano3: ''
                },
                {
                    ano1: '',
                    ano2: '',
                    ano3: ''
                },
                {
                    ano1: '',
                    ano2: '',
                    ano3: ''
                },
                {
                    ano1: '',
                    ano2: '',
                    ano3: ''
                }
            ],

            files: [{}, {}, {}, {}, {}],

            status: 'under_review',
            notified: false,

            rgpd: false,
        }

    }

    const [, set_dados] = useState(null);
    const dados = useRef(data_interface);

    const [refresh, setRefresh] = useState(0);

    const handle_change_info = (e) => {
        const { name, value } = e.target;

        //console.log('NAME: ', name)
        //console.log('VALUE: ', value)

        set_dados(dados.current.info[name] = value)
        console.log('Dados Current:' + JSON.stringify(dados))
    }

    const handle_change_info_2 = (e, linha, coluna) => {
        const {value } = e.target;

        //console.log('NAME: ', name)
        //console.log('VALUE: ', value)

        set_dados(dados.current.info['previsions'][linha]['ano' + coluna] = value)
        console.log('Dados Current:' + JSON.stringify(dados))
    }

    let mailOptionsUser = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: dados.current.info.email_01,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        cc: dados.current.info.email,
        subject: Hosts.webSiteTitle + ' - ' + language[lang].contest,
        //text: 'Teste de EMAIL ', -> opcional
        html:
            '<html><head></head><body style="background-color:#F7F7F7;">' +
            '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
            '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td style="border-collapse: collapse;">' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '" target="_blank">' +
            '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
            '</a>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
            '<div style="width:540px; margin: 30px;">' +
            '<h4 style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;">' + language[lang].app_form_title + ':</h4>' +
            '<p style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;">' + language[lang].hello + ',' + dados.current.info.name_01 + '.</p>' +
            '<p style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;">' + language[lang].email_contest + '</p>' +
            '<p style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;">' + language[lang].email_contest_02 + '</p>' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR + '; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
            '<div style="margin: 0 auto; width:600px;">' +
            '<div style="text-align: center; font-size: small; color: #FFFFFF;">' +
            '<span style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteTitle + '</span>' +
            '<br/>' +
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteAdress + '</span>' +
            '<br/>' +
            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '" target="_blank" style="font-family:Arial,Helvetica,sans-serif;text-decoration:none;"> ' +
            '<span style="color: #FFFFFF;font-family:Arial,Helvetica,sans-serif;font-size:12px;" >' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '</span>' +
            '</a>' +
            '<br/>' +
            '</div>' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</div>' +
            '<body><html>',
    };

    let mailOptionsAciba = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: Hosts.contactFrom,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        //cc: Hosts.contactFrom,
        bcc: 'web.dev@assec.pt',
        subject: language[lang].email_subject_01,
        //text: 'Teste de EMAIL ', -> opcional
        html:
            '<html><head></head><body style="background-color:#F7F7F7;">' +
            '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
            '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td style="border-collapse: collapse;">' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '" target="_blank">' +
            '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
            '</a>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
            '<div style="width:540px; margin: 30px;">' +
            '<h4 style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;">' + language[lang].email_contest_new_application + ':</h4>' +
            '<p style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;">' + language[lang].email_contest_03 + '</p>' +
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>' + language[lang].contest + ': </b><label>' + language[lang][dados.current.info.type] + '</label></span>' +
            '<br/>'+
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>' + language[lang].name + ': </b><label>' + dados.current.info.name_01 + '</label></span>' +
            '<br/>'+
            '<br/>'+
            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_BACKOFFICE_URL_TEXT  + '/contest_form_list" target="_blank" style="font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + language[lang].email_contest_button + '</a>'+
            '<br/>'+
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR + '; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
            '<div style="margin: 0 auto; width:600px;">' +
            '<div style="text-align: center; font-size: small; color: #FFFFFF;">' +
            '<span style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteTitle + '</span>' +
            '<br/>' +
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteAdress + '</span>' +
            '<br/>' +
            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '" target="_blank" style="font-family:Arial,Helvetica,sans-serif;text-decoration:none;"> ' +
            '<span style="color: #FFFFFF;font-family:Arial,Helvetica,sans-serif;font-size:12px;" >' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '</span>' +
            '</a>' +
            '<br/>' +
            '</div>' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</div>' +
            '<body><html>',
    };

    const handleSubmit = async e => {
        e.preventDefault();


        //Swal.fire('Loading...')

        Swal.fire({
            icon: 'warning',
            title: '',
            html: "A enviar o formulário.</br> Aguarde.",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
        })



        //alert('SUBMIT');
        //return;

        var today_submit = new Date()
        dados.current.info['uniqid'] = uid
        dados.current.info['date'] = today_submit

        reqPOST(`${Hosts.SIMCore_API}/contest/form`, dados.current)
            .then(() => {

                reqGET(`${Hosts.SIMCore_API}/contest/form/uniqid/${Hosts.companycode}/${uid}`)
                    .then(res => {

                        dados.current.id = res.data[0].id

                        files_doc.forEach(async (el_doc, k) => {
                            if (el_doc !== null) {
                                await uploadFilesDoc(el_doc.files, res.data[0].id, k)
                            }
                        })
                        set_files_doc([])
                        set_files_doc_name([])

                        reqPOST(`${Hosts.SIMCore_API_MAIL}`, { data: mailOptionsUser })
                            .then(() => {

                                reqPOST(`${Hosts.SIMCore_API_MAIL}`, { data: mailOptionsAciba })
                                    .then(() => {


                                        Swal.close()

                                        //console.log('Notificações enviadas com sucesso') 
                                        // set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                                        // document.getElementById('agree').checked = false
                                        //setAgree(false)
                                        //setRefresh(refresh + 1)
                                        Swal.fire({
                                            title: language[lang].app_form_title,
                                            text: language[lang].sent_successfully,
                                            icon: 'success',
                                            //showCancelButton: true,
                                            confirmButtonColor: '#08396a',
                                            //confirmButtonText: 'Tentar novamente',
                                        }).then((result) => {
                                            if (result.isConfirmed) {

                                                setTimeout(() => {
                                                    set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                                                    document.getElementById('agree2').checked = false
                                                }, 1000)

                                            }
                                        })
                                        

                                    })
                                    .catch((error) => console.log(error))

                    })
                    .catch((error) => console.log(error))

                    })
                    .catch(() => 404)

            })
            .catch(() => 404)
    }


    const onChangeHandlerFile = (e, k) => {
        const { files } = e.target;

        let storedFiles = [];
        let filesArray = Array.prototype.slice.call(files);

        filesArray.forEach(async (v) => {
            // if((v.type.includes('/pdf')) && (v.size < 10485760)) {
            storedFiles.push(v);

            let aux2 = files_doc_name
            aux2[k] = { name: v.name, size: v.size }
            set_files_doc_name(aux2)

            set_dados(dados.current,)
            setRefresh(refresh + 1)
            // }
            // else {
            // 	alert(language[lang].upload_file_error + v.name);
            // }
        });

        let files_aux = files_doc
        //files_aux.push({ files: storedFiles })
        files_aux[k] = { files: storedFiles }
        set_files_doc(files_aux)

    }

    const removeArticleFile = (file, key) => () => {

        console.log('fd', files_doc)
        console.log('fdn', files_doc_name)
        //console.log('file', file)
        console.log('posição', key)

        files_doc.forEach((v) => {
            v.files.forEach((v2, k2) => {
                if(v2.name === file.name) {
                    v.files.splice(k2, 1)
                }
            })
        })

        files_doc_name.forEach((v, k) => {
            if(v !== undefined && v !== null) {
                if(v.name === file.name) {
                    //files_doc_name.splice(k, 1)
                    files_doc_name[k] = undefined
                }
            }
        })
        set_files_doc(files_doc)
        set_files_doc_name(files_doc_name)

        document.getElementById("form_02_input_file_"+key).value = null;
        //console.log('input 0', document.getElementById("form_02_input_file_0").value)
        //console.log('input 1', document.getElementById("form_02_input_file_1").value)
        //console.log('input 2', document.getElementById("form_02_input_file_2").value)
        //console.log('input 3', document.getElementById("form_02_input_file_3").value)
        //console.log('input 4', document.getElementById("form_02_input_file_4").value)

        setRefresh(refresh + 1)

        console.log('FILES DOC 2', files_doc)

		//const newFiles = [...myFiles]
		
		//console.log('«««««««««««««««««««««««')
		//console.log('Dados Current:' + JSON.stringify(dados))
		//setMyFiles(newFiles)
	}

    const uploadFilesDoc = (files, id, k) => {
        let aux = []
        files.forEach((element) => {
            // if(element.type === "application/pdf") {
            aux.push(element)

            uploadFile(element, id, k)
                .then(() => {
                    //console.log('success')
                })
            // }
            // else {
            // 	Swal.fire('Formato do ficheiro incorrecto!', '', 'info')
            // }
        })
    }

    const uploadFile = async (file, id, k) => {
        const formData = new FormData()
        formData.append('files', file)
        formData.append('id', id)
        formData.append('file_name', file.name)
        formData.append('type', 'contest')
        formData.append('companycode', dados.current.info.companycode)
        formData.append('uniqid', dados.current.info.uniqid)
        formData.append('folder', Hosts.APP_REPO_FOLDER + 'upload/app/');

        return await reqFILE(`${Hosts.SIMCore_API}/multimedia/file`, formData)
            .then((res) => {

                console.log('RES DATA', res.data)

                dados.current.info.files[k] = {
                    link: res.data.path + '/' + res.data.name,
                    name: res.data.name,
                }

                //set_dados(dados.current,)

                console.log('«««««««««««««««««««««««')
		        console.log('Dados Current:' + JSON.stringify(dados))

                reqPUT(`${Hosts.SIMCore_API}/contest/form`, dados.current)
                    .then(() => {
                        
                        //props.history.push(endpoints.list) 
                    })
                    .catch(() => Swal.fire(language[lang].edit_error, '', 'info'))

                return true

            })
            .catch(erro => console.log('Erro POST File: ' + erro))
    }

    /*const handleFileUpload = event => {
        const name = event.target.name
        const files = event.target.files
        const formData = new FormData()
        formData.append('course', dados.current.info.course.id)
        formData.append('vat', dados.current.info.vat)
        formData.append('tipo', name)
        formData.append('files', files[0])

        reqFILE(`${Hosts.SIMCore_API}/registration/file`, formData)
            .then((res) => {

                dados.current.info.files = dados.current.info.files.filter(v => v.tipo !== res.data.tipo);
                dados.current.info.files.push(
                    {
                        path: res.data.path,
                        tipo: res.data.tipo,
                        name: res.data.name,
                        ext: res.data.mimetype,
                    }
                )
                setRefresh(refresh + 1)

                console.log('sucesso POST File')

            })
            .catch(erro => console.log('Erro POST File: ' + erro))
    
        }*/

    return (
        <div>
            {/* <Navbar mostrar_botao_voltar={true} /> */}
            <section className="mb-5">

                <div className="container-fluid bg-color-gray-light mt-4">
                    <div className="container py-4 mb-4">
                        <h3 className="titulo-primario mb-0 d-none">{language[lang].app_form_title}</h3>
                        <div className="" style={{color: 'var(--body)', fontWeight: 500, fontSize: 'small', display: 'flex', alignItems: 'center'}}><i className="bi bi-exclamation-circle fs-4" style={{fontSize: '1.5rem', color: '#f5a719', paddingRight: '15px'}} /> {language[lang].tipo2_txt2}</div>
                    </div>
                </div>

                <div className="container mt-5">
                    <form onSubmit={handleSubmit} id="app_form">


                        <div className="col-12">
                            <p>
                                {language[lang].read_terms_conditions}
                                {/*Leia os nossos <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">termos e condições</a> antes de submeter o formulário. Ao submeter concorda com os termos do nosso serviço.*/}
                            </p>
                        </div>

                        <div className="col-12 mt-3 font-weight-bold">
                            <label className="mb-4">{language[lang].app_form_title_01}</label>
                            <div className="row">
                                <div className="col-12 col-sm-8">
                                    <label className="control-label">{language[lang].name}*</label>
                                    <input type="text" name="name_01"
                                        value={dados.current.info.name_01}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].email}*</label>
                                    <input type="text" name="email_01"
                                        value={dados.current.info.email_01}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 font-weight-bold">
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <label className="control-label">{language[lang].contact}*</label>
                                    <input type="text" name="contact_01"
                                        value={dados.current.info.contact_01}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].date_birth}*</label>
                                    <input type="date" name="date_birth_01"
                                        value={dados.current.info.date_birth_01}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].qualifications}*</label>
                                    <input type="text" name="qualifications_01"
                                        value={dados.current.info.qualifications_01}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <div className="row">
                                <div className="col-12 col-sm-8">
                                    <label className="control-label">{language[lang].name}</label>
                                    <input type="text" name="name_02"
                                        value={dados.current.info.name_02}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].email}</label>
                                    <input type="text" name="email_02"
                                        value={dados.current.info.email_02}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 font-weight-bold">
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <label className="control-label">{language[lang].contact}</label>
                                    <input type="text" name="contact_02"
                                        value={dados.current.info.contact_02}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].date_birth}</label>
                                    <input type="date" name="date_birth_02"
                                        value={dados.current.info.date_birth_02}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].qualifications}</label>
                                    <input type="text" name="qualifications_02"
                                        value={dados.current.info.qualifications_02}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <div className="row">
                                <div className="col-12 col-sm-8">
                                    <label className="control-label">{language[lang].name}</label>
                                    <input type="text" name="name_03"
                                        value={dados.current.info.name_03}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].email}</label>
                                    <input type="text" name="email_03"
                                        value={dados.current.info.email_03}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3 font-weight-bold">
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <label className="control-label">{language[lang].contact}</label>
                                    <input type="text" name="contact_03"
                                        value={dados.current.info.contact_03}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].date_birth}</label>
                                    <input type="date" name="date_birth_03"
                                        value={dados.current.info.date_birth_03}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                                    <label className="control-label">{language[lang].qualifications}</label>
                                    <input type="text" name="qualifications_03"
                                        value={dados.current.info.qualifications_03}
                                        className="form-control"
                                        placeholder=""
                                        onChange={handle_change_info}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <label className="control-label">{language[lang].email_02}*</label>
                            <input type="text" name="email" value={dados.current.info.email}
                                className="form-control" placeholder="" onChange={handle_change_info} required
                            />
                        </div>

                        <div className="col-12 mt-3">
                            <label className="control-label">{language[lang].designation}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="designation" name="designation" value={dados.current.info.designation} onChange={handle_change_info} //required 
                        />
                        </div>

                        <div className="col-12 mt-3">
                            <label className="control-label">{language[lang].location}*</label>
                            <input className="form-control" type="text" id="location" name="location" value={dados.current.info.location} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_02}*</label></div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_01" name="option_02" onChange={handle_change_info} value="app_form_option_02_01" />
                                    <label className="pl-2" htmlFor="option_02_01">{language[lang].app_form_option_02_01}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_03" name="option_02" onChange={handle_change_info} value="app_form_option_02_03" />
                                    <label className="pl-2" htmlFor="option_02_03">{language[lang].app_form_option_02_03}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_04" name="option_02" onChange={handle_change_info} value="app_form_option_02_04" />
                                    <label className="pl-2" htmlFor="option_02_04">{language[lang].app_form_option_02_04}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_05" name="option_02" onChange={handle_change_info} value="app_form_option_02_05" />
                                    <label className="pl-2" htmlFor="option_02_05">{language[lang].app_form_option_02_05}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_06" name="option_02" onChange={handle_change_info} value="app_form_option_02_06" />
                                    <label className="pl-2" htmlFor="option_02_06">{language[lang].app_form_option_02_06}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_07" name="option_02" onChange={handle_change_info} value="app_form_option_02_07" />
                                    <label className="pl-2" htmlFor="option_02_07">{language[lang].app_form_option_02_07}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_08" name="option_02" onChange={handle_change_info} value="app_form_option_02_08" />
                                    <label className="pl-2" htmlFor="option_02_08">{language[lang].app_form_option_02_08}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_10" name="option_02" onChange={handle_change_info} value="app_form_option_02_10" />
                                    <label className="pl-2" htmlFor="option_02_10">{language[lang].app_form_option_02_10}</label>
                                </div>
                                <div className="col-6 col-sm-3 mt-3">
                                    <input type="radio" id="option_02_09" name="option_02" onChange={handle_change_info} value="app_form_option_02_09" />
                                    <label className="pl-2" htmlFor="option_02_09">{language[lang].app_form_option_02_09}</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_03}*</label></div>
                        <div className="d-sm-flex">
                            <div className="col-6 col-sm-3 mt-3">
                                <input type="radio" id="option_03_01" name="option_03" onChange={handle_change_info} value="app_form_option_03_01" />
                                <label className="pl-2" htmlFor="option_03_01">{language[lang].app_form_option_03_01}</label>
                            </div>
                            <div className="col-6 col-sm-3 mt-3">
                                <input type="radio" id="option_03_02" name="option_03" onChange={handle_change_info} value="app_form_option_03_02" />
                                <label className="pl-2" htmlFor="option_03_02">{language[lang].app_form_option_03_02}</label>
                            </div>
                            <div className="col-6 col-sm-3 mt-3">
                                <input type="radio" id="option_03_03" name="option_03" onChange={handle_change_info} value="app_form_option_03_03" />
                                <label className="pl-2" htmlFor="option_03_03">{language[lang].app_form_option_03_03}</label>
                            </div>
                            <div className="col-6 col-sm-3 mt-3">
                                <input type="radio" id="option_03_04" name="option_03" onChange={handle_change_info} value="app_form_option_03_04" />
                                <label className="pl-2" htmlFor="option_03_04">{language[lang].app_form_option_03_04}</label>
                            </div>
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_04}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            {/*TextareaCounter countLimit={200}*/}
                            <textarea style={{ height: "120px", width: "100%" }} id="description_01" name="description_01" value={dados.current.info.description_01} onChange={handle_change_info} 
                            required 
                        />
                            {/* <input type="text" name="message" value={dados.current.info.message} className="form-control" placeholder="" onChange={handle_change_info} /> */}
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_05}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_02" name="description_02" value={dados.current.info.description_02} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_07}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_04" name="description_04" value={dados.current.info.description_04} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_08}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_05" name="description_05" value={dados.current.info.description_05} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_09}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_06" name="description_06" value={dados.current.info.description_06} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_10}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_07" name="description_07" value={dados.current.info.description_07} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_11}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_08" name="description_08" value={dados.current.info.description_08} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_12}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_09" name="description_09" value={dados.current.info.description_09} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_06}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_03" name="description_03" value={dados.current.info.description_03} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_14}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_11" name="description_11" value={dados.current.info.description_11} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_13}*</label></div>
                        <div className="col-12">
                            <label className="control-label d-none">{language[lang].description}*</label>
                            <textarea style={{ height: "120px", width: "100%" }} id="description_10" name="description_10" value={dados.current.info.description_10} onChange={handle_change_info} 
                            required 
                        />
                        </div>

                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_15}*</label></div>
                        <div className="col-12">
                            <table>
                                <thead>
                                    <tr>
                                        {/*console.log('DADOS', dados.current.info.previsions)*/}
                                        <th></th>
                                        <th>1ºano</th>
                                        <th>2ºano</th>
                                        <th>3ºano</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dados.current.info.previsions.map((v, key) => {
                                        return(
                                            <tr key={key}>

                                                <td>
                                                    {language[lang]['previsions_' + key]}
                                                </td>
                                                <td>
                                                    <input type="number" name="ano1"
                                                        value={v.ano1}
                                                        className="form-control"
                                                        placeholder=""
                                                        onChange={(e)=> handle_change_info_2(e, key, '1')}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <input type="number" name="ano2"
                                                        value={v.ano2}
                                                        className="form-control"
                                                        placeholder=""
                                                        onChange={(e)=> handle_change_info_2(e, key, '2')}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <input type="number" name="ano3"
                                                        value={v.ano3}
                                                        className="form-control"
                                                        placeholder=""
                                                        onChange={(e)=> handle_change_info_2(e, key, '3')}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="col-12 mt-3 font-weight-bold"><label>{language[lang].app_form_title_16}*</label></div>
                        <div className="col-12 col-sm-6 mt-3">
                            <label className="control-label">{language[lang].cv_file_01}*</label>
                            <span className="custom-file-label overflow-input">{files_doc_name[0] !== undefined ? files_doc_name[0].name : ''}</span>
                            <input
                                id="form_02_input_file_0"
                                name="docs"
                                className="custom-file-input"
                                //defaultValue={files_doc_name[0] !== undefined ? files_doc_name[0].name : ''}
                                type="file"
                                accept="image/png,image/gif,image/jpeg,application/pdf"
                                onChange={(e) => onChangeHandlerFile(e, '0')}
                                required
                                //disabled={(dados.current.info.vat === '' || dados.current.info.course.id === '')}
                                //multiple
                            />
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                            <label className="control-label">{language[lang].cv_file_02}</label>
                            <span className="custom-file-label overflow-input">{files_doc_name[1] !== undefined ? files_doc_name[1].name : ''}</span>
                            <input
                                id="form_02_input_file_1"
                                name="docs"
                                className="custom-file-input"
                                type="file"
                                accept="image/png,image/gif,image/jpeg,application/pdf"
                                onChange={(e) => onChangeHandlerFile(e, '1')}
                                //disabled={(dados.current.info.vat === '' || dados.current.info.course.id === '')}
                                //multiple
                            />
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                            <label className="control-label">{language[lang].cv_file_03}</label>
                            <span className="custom-file-label overflow-input">{files_doc_name[2] !== undefined ? files_doc_name[2].name : ''}</span>
                            <input
                                id="form_02_input_file_2"
                                name="docs"
                                className="custom-file-input"
                                type="file"
                                accept="image/png,image/gif,image/jpeg,application/pdf"
                                onChange={(e) => onChangeHandlerFile(e, '2')}
                                //disabled={(dados.current.info.vat === '' || dados.current.info.course.id === '')}
                                //multiple
                            />
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                            <label className="control-label">{language[lang].project_idea_file}*</label>
                            <span className="custom-file-label overflow-input">{files_doc_name[3] !== undefined ? files_doc_name[3].name : ''}</span>
                            <input
                                id="form_02_input_file_3"
                                name="docs"
                                className="custom-file-input"
                                type="file"
                                accept="image/png,image/gif,image/jpeg,application/pdf"
                                onChange={(e) => onChangeHandlerFile(e, '3')}
                                required
                                //disabled={(dados.current.info.vat === '' || dados.current.info.course.id === '')}
                                //multiple
                            />
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                            <label className="control-label">{language[lang].declaration_file}*</label>
                            <span className="custom-file-label overflow-input">{files_doc_name[4] !== undefined ? files_doc_name[4].name : ''}</span>
                            <input
                                id="form_02_input_file_4"
                                name="docs"
                                className="custom-file-input"
                                type="file"
                                accept="image/png,image/gif,image/jpeg,application/pdf"
                                onChange={(e) => onChangeHandlerFile(e, '4')}
                                required
                                //disabled={(dados.current.info.vat === '' || dados.current.info.course.id === '')}
                                //multiple
                            />
                        </div>
                        {/* <div className="col-12 col-sm-6 mt-3">
                            <label className="control-label">{language[lang].files}</label>
                            <span className="custom-file-label"></span>
                            <input
                                name="docs"
                                className="custom-file-input"
                                type="file"
                                accept="image/png,image/gif,image/jpeg,application/pdf"
                                onChange={(e) => onChangeHandlerFile(e, '3')}
                                //disabled={(dados.current.info.vat === '' || dados.current.info.course.id === '')}
                                //multiple
                            />
                        </div> */}

                        <div className="col-12 mt-3">
                            {files_doc_name.map((file, key) => {
                                if(file !== undefined && file !== null) {
                                    return (
                                        <div key={key}>        
                                            {file.name}
                                            <button type="button" className="upload-button ml-3 remove-file" title={language[lang].remove_file} alt={language[lang].remove_file} onClick={removeArticleFile(file, key)}>X</button>
                                        </div>
                                    )
                                }
                                else { 
                                    return null
                                }
                            }
                            )}
                        </div>

                        <div className="col-12 mt-3">
                            <input type="checkbox" id="agree2" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)} />
                            <label className="pl-2" htmlFor="agree2">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
                            <label className="pl-2 small" htmlFor="agree2">{language[lang].terms_conditions_required}</label>
                        </div>

                        <div className="text-right">
                            <Button disabled={!agree} className="mt-5 mx-auto" color="primary">{language[lang].send}</Button>
                            {/*<Button disabled className="mt-5 mx-auto" color="primary">{language[lang].send}</Button>*/}
                        </div>


                    </form>
                </div>

            </section>
            {/* <Footer /> */}
        </div>
    );


}

export default AppForm;
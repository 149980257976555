import React, { useState, useEffect } from 'react';

/* import { Link } from 'react-router-dom'; */

import Slider from "./Slider/Slider";
import Footer from "./Footer/Footer";
import ContactForm from "./Forms/ContactForm";

import parse from 'html-react-parser';

import Navbar from "./Navbar/Navbar";

import "./Pagina.css";

import Hosts from '../../Hosts';
import {
  set_language,
  reqGET,
  repo_img_link,
  repo_logo_link,
  content_language

} from '../../Utils';

// get our fontawesome imports
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from './WEBContent/Language';

import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { EffectFade } from 'swiper';

import Modal from "./Forms/OpinionModal"


const Pagina = props => {
  /* variável do tipo array */

  const [/*isloading*/, setLoading] = useState(true);

  const [lang, set_lang] = useState('pt');
  const [lang_id, set_lang_id] = useState('1');

  const [dataProject, setDataProject] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  const [dataPlatform, setDataPlatform] = useState([]);
  const [dataTestimony, setDataTestimony] = useState([]);
  const [dataContact, setDataContact] = useState([]);
  const [, setBanner] = useState([]);

  const [show, setShow] = useState(false);
  
  const aPlatformLinks = ['https://eucei.pt/elearning', 'https://eucei.pt/planodenegocios', 'https://eucei.pt/benchmarking'];

  let getInfo = async () => {
    //setLoading(true)
    reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/banner`)
      .then(res => {
        setBanner(res.data)
        //console.log(res.data)
        setLoading(false)
        //calculo_margem()
      })
      .catch(erro => console.log('Erro', erro))
  }

  let getInfoProject = async () => {
    //setLoading(true)

    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/48`)
      .then(res => {
        setDataProject(res.data)
        //console.log('RES DATA', res.data[0])

      })
      .catch(erro => alert('Erro'))
  }

  let getInfoEntrepreneur = async () => {
    //setLoading(true)

    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/49`)
      .then(res => {
        setDataInfo(res.data)
        //console.log('RES DATA', res.data[0])

      })
      .catch(erro => alert('Erro'))
  }
  let getInfoPlatforms = async () => {
    //setLoading(true)

    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/52`)
      .then(res => {
        setDataPlatform(res.data)
        //console.log('PLATAFORMAS', res.data)

      })
      .catch(erro => alert('Erro'))
  }

  let getTestimony = async () => {
    //setLoading(true)

    reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/testimony`)
      .then(res => {
        setDataTestimony(res.data.filter(v => v.info.status === 'accepted'))
        //console.log('PLATAFORMAS', res.data)

      })
      .catch(erro => alert('Erro'))
  }

  let getInfoContact = async () => {
    //setLoading(true)

    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/105`)
      .then(res => {
        setDataContact(res.data)
        //console.log('RES DATA', res.data[0])

      })
      .catch(erro => alert('Erro'))
  }

  useEffect(() => {
    getInfo()
    getInfoProject()
    getInfoEntrepreneur()
    getInfoPlatforms()
    getTestimony()
    getInfoContact()

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_language('pt')
      set_lang_id('1')
    }
    else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
        }
      })
    }

  }, [])


  return (

    <div>
      <Navbar />
      <section className="margemBody">
        <Slider />

        <section id="project" className="">
          <div className="container-fluid py-5 mt-4 mt-sm-5">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">

                  {dataProject.map((v, key) => {
                    return (
                      //console.log('PROJECT', v.content[0].text)
                      <div key={key}>
                        <div className="mb-5">
                          <h3 className="titulo-primario">
                            {/*{language[lang].project}*/}
                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                          </h3>
                          <p className="subtitulo-secundario">
                            {/*{language[lang].project_banner_text}*/}
                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                          </p>
                        </div>
                        <div className="column-count-2 text-left">
                          {/*{parse(v.content[0].text)}*/}
                          {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                        </div>
                      </div>
                    )
                  })
                  }

                </div>
              </div>
            </div>
            <hr className="mt-100"></hr>
          </div>
        </section>

        <section id="app" className="parallax" style={{ backgroundImage: `url(` + repo_img_link("banner-plataformas.png") + `)` }}>
          <div className="container-fluid py-5">
            <div className="container mb-4">
              <div className="row">
                <div className="col-12 ">
                  {
                    dataPlatform.map((v, key) => {
                      //console.log('------------> V', v)
                      return (
                        <div key={key}>
                          <div className="mb-5 text-center">
                            <h3 className="titulo-primario">
                              {/*{language[lang].project}*/}
                              {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                            </h3>
                            <p className="subtitulo-secundario">
                              {/*{language[lang].project_banner_text}*/}
                              {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                            </p>
                          </div>
                          <div className="column-count-2 text-left">
                            {/*{parse(v.content[0].text)}*/}
                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                          </div>
                          <div className="row">
                            {
                              v.content.slice(1).map((v2, key2) => {
                                let color = key2 === 1 ? '' : (key2 === 0 ? '-red' : '-orange');
                                //let link_business_plan = 'https://eucei.pt/planodenegocios'
                                return (
                                  //console.log('PROJECT', v.content[0].text)
                                  <div className="col-12 col-lg-4 mt-4 mt-sm-0" key={key2} >
                                    <div className="col-12 text-center">
                                    {
                                      /*key2 === 1 ?
                                        <a href={link_business_plan} alt={v2.title} title={v2.title} target="_blank" rel="noreferrer">
                                          <img className="my-3" src={v2.media.image[0].link} alt={v2.title} height="80px" />
                                        </a>
                                      :
                                        <img className="my-3" src={v2.media.image[0].link} alt={v2.title} title={v2.title} height="80px" />*/
                                    }
                                      <a href={aPlatformLinks[key2]} alt={v2.title} title={v2.title} target="_blank" rel="noreferrer">
                                          <img className="my-3" src={v2.media.image[0].link} alt={v2.title} height="80px" />
                                        </a>
                                      <p className="titulo-primario mb-3">{v2.title}</p>
                                    </div>
                                    {parse(v2.text)}
                                    <div className={"mt-4"}>
                                      <a href={aPlatformLinks[key2]} alt={v2.title} title={v2.title} className={"color" + color} target="_blank" rel="noreferrer">
                                        {language[lang].go_platform}
                                        <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} />
                                      </a>
                                    </div>
                                    {
                                      /*key2 === 1 ?

                                        <div className={"mt-4"}>
                                          <a href={link_business_plan} alt={v2.title} title={v2.title} className={"color" + color} target="_blank" rel="noreferrer">
                                            {language[lang].go_platform}
                                            <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} />
                                          </a>
                                        </div>
                                      :
                                        <div className={"mt-4 d-none"}>
                                          <a href={"."} className={"color" + color} target="_blank" rel="noreferrer">
                                            {language[lang].go_platform}
                                            <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} />
                                          </a>
                                        </div>*/
                                    }
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="info" className="bg-color-gray-light py-100">
          <div className="container">
            <div className="row">

              <div className="col-12 text-center">

                {dataInfo.map((v, key) => {
                  return (
                    <div key={key}>
                      <div className="mb-5">
                        <h3 className="titulo-primario">
                          {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                        </h3>
                        <p className="subtitulo-secundario">
                          {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                        </p>
                      </div>
                      <div className="row text-left">
                        {v.content.slice(1).map((v2, key2) => {
                          let color = key2 === 0 || key2 === 3 || key2 === 6 ? '' : (key2 === 1 || key2 === 4 || key2 === 7 ? '-red' : '-orange');
                          return (
                            <div key={key2} className="accordion col-12 col-sm-6 col-md-4 w-100">
                              <div className={`card${key2}`}>
                                <div className="card-header" id={`heading${key2}`}>
                                  <button className={"text-left btn collapsed btn-link" + color} data-toggle="collapse" data-target={`#collapseExample${key2}`} aria-expanded="true" aria-controls="collapseOne">
                                    {/* <p className="font-weight-bold w-100 mb-0">{v.content.title}</p> */}
                                    <span style={{ color: 'var(--body)' }}>
                                      {parse(lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title))}
                                    </span>
                                  </button>
                                </div>

                                <div id={`collapseExample${key2}`} className="collapse" aria-labelledby={`heading${key2}`}>
                                  <div className="card-body">
                                    {parse(lang === 'pt' || v2.language === undefined ? v2.text : (v2.language[lang_id] === undefined || v2.language[lang_id].text === undefined || v2.language[lang_id].text === '' ? v2.text : v2.language[lang_id].text))}
                                    {/*getCkEditorVideos()*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                  )
                })
                }

                {/*<h3 className="titulo-primario">{language[lang].entrepreneur_information}</h3>
                <p className="subtitulo-secundario">{language[lang].info_banner_text}</p>*/}

                <div className="w-100 d-none">
                  <p className="text-white float-left">{language[lang].banner_submission_2}</p>
                  <a href={Hosts.WEB_SITE_URI + 'submissions'} className="btn btn-primary float-right">
                    {language[lang].learn_more}
                  </a>
                </div>

              </div>

            </div>
          </div>
        </section>

        <section id="opinion" className="parallax pb-5" style={{ backgroundImage: `url(` + repo_img_link("banner-eucei.png") + `)` }}>
          <div className="container-fluid py-5">
            <div className="container mt-4 mb-4">
              <div className="row">
                <div className="col-12 col-sm-10 offset-sm-1 text-center">
                  <div className="mb-5">
                    <h3 className="titulo-primario">{language[lang].testimonials}</h3>
                    <p className="subtitulo-secundario">{language[lang].opinion_banner_text}</p>
                  </div>

                  <div className="col-12">
                    <div className="swiper-container slideshow">
                    <Swiper
                          modules={[Autoplay, Navigation, EffectFade, Pagination, Scrollbar, A11y]}

                          speed={7000}
                          loop={false}
                          autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                          }}

                          spaceBetween={0}
                          slidesPerView={1}
                          //navigation
                          pagination={{ 
                            el : '.swiper-pagination-opinion',
                            clickable: true }}
                          //scrollbar={{ draggable: true }}
                        >
                        {dataTestimony.map((v, key) => {
                          return (
                            <SwiperSlide key={key}>
                              <div className='p-3'> 
                                {parse(v.content[0].message)}
                                <p><b>{parse(v.content[0].name)}</b></p>
                              </div>
                            </SwiperSlide>
                          )
                        })}

                        {/* 
                        <SwiperSlide>"O projeto mais esclarecedor, excelente trabalho."</SwiperSlide>
                        <SwiperSlide>"Ótimo trabalho, irei partilhar o vosso projeto eu.cei."</SwiperSlide> 
                        */}
                      </Swiper>
                    </div>
                    
                    <div className={"swiper-pagination-opinion my-3 text-center " + (dataTestimony.length > 1 ? 'd-none d-lg-block' : 'd-none' )}></div>
                  </div>

                  <div className="col-12 mt-3">
                    <button /*onClick={() => setShow(true)}*/ data-toggle="modal" data-target={"#modal_form"} className="btn btn-primary">{language[lang].opinion_banner_button}</button>
                    <Modal onClose={() => setShow(false)} show={show} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section id="contacts" className="bg-color-gray-light py-100">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-4">
                  <img src={repo_logo_link("logo.svg")} width="180px" height="75px" alt={Hosts.webSiteTitle} className="img-fluid" />
                </div>
                <div className="mb-5">
                  {dataContact.map((v, key) => {
                    return (
                      <div key={key}>
                        <div className="my-5">
                          <p className="subtitulo-secundario">
                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                          </p>
                        </div>
                        <div className="">
                          {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
                      
              </div>
              <div className="col-12 col-md-6">
                
                < ContactForm />
                      
              </div>
            </div>
          </div>
          <hr className="mt-100 d-none"></hr>
        </div>
      </section>

      <section><Footer /></section>
    </div>
  )
}

export default Pagina;
import React, {useState, useEffect} from 'react';
//import {useLocation } from 'react-router-dom';

import "../Pagina.css";

import parse from 'html-react-parser';

import { language } from '../WEBContent/Language';

import { 
    set_language,

} from '../../../Utils';

import CookieConsent from "react-cookie-consent";


import Hosts from "../../../Hosts";


const Cookies = () => {

    const [lang, set_lang] = useState('pt');

    //const page_location = useLocation().pathname.split('/').pop();

    useEffect(() => {
		
        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
        }
        else {
            set_lang(localStorage.getItem('lang'))
        }

	}, [])

    return (

        <div>
            <CookieConsent
                /*debug={true}*/
                buttonText={language[lang].cookie_button_text}
                buttonClasses={"btn-cookies"}
                containerClasses="containerCookie"
                contentClasses="messageCookie"
                >
                <div className="col-12 text-center">
                    {parse(language[lang].cookies_message)}&nbsp;
                    <a href={Hosts.WEB_SITE_URI + "terms_conditions"}>{language[lang].cookie_more}</a>
                </div>
            
            </CookieConsent>
        </div >
    )
};
export default Cookies;
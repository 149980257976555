var crypto = require('crypto');
export const Hosts = require('../src/Hosts');

export const requests = async (url, request_type, body_data) => {

    let localtoken = (JSON.parse(localStorage.getItem('token_site')))
    //console.log('TOKEN NO UTILS:', localtoken)
    const requestOptions = {
        method: request_type,

        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + (localtoken === null ? '' : Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN)
        },
        body: JSON.stringify(body_data)

    }
    //console.log(localtoken)
    return await fetch(url, requestOptions).then(response => response.json())
    //.then(res => { //console.log(res); return res })
}

export const requests_file = async (url, request_type, body_data) => {

    let localtoken = (JSON.parse(localStorage.getItem('token_site')))
    //console.log('TOKEN NO UTILS:', localtoken)
    const requestOptions = {
        method: request_type,
        headers: {
            'Authorization': 'Bearer ' + (localtoken === null ? '' : Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN)
        },
        body: body_data

    }
    //console.log(localtoken)
    return await fetch(url, requestOptions).then(response => response.json())
    //.then(res => { //console.log(res); return res })
}

export const reqGET = async (url) => {
    return await requests(url, 'GET')
}

export const reqPOST = async (url, data) => {
    return await requests(url, 'POST', data)
}

export const reqPUT = async (url, data) => {
    return await requests(url, 'PUT', data)
}

export const reqDELETE = async (url, data) => {
    return await requests(url, 'DELETE', data)
}

export const reqFILE = async (url, data) => {
    return await requests_file(url, 'POST', data)
}

export const load_countries_pt = async () => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/langpt/list`)
}

export const load_districts_pt = async () => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/districts`)
}

export const load_cities_of_district_pt = async district => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/citiesofdistrict/${district}`)
}

export const load_district_of_city_pt = async city => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/districtofcity/${city}`)
}

export const load_freguesias_pt = async city => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/villagesofcity/${city}`)
}

export const get_vat_info = async vat => {
    return await reqGET(`${Hosts.SIMCore_API}/vat/${vat}`)
}


export const select_option_util_fun = (arg, set_fun, field) => {
    set_fun(...[arg.map(k => ({ value: k[field], label: k[field] }))])
}

export const select_regist_util_fun = (arg, field) => {
    return arg[0][field]
}


export const obter_cidades_por_distrito = (distrito, set_fun) => load_cities_of_district_pt(distrito).then(res => select_option_util_fun(res.data, set_fun, 'langpt_cc'))

export const obter_distrito_por_cidade = (cidade) => load_district_of_city_pt(cidade).then(res => { return select_regist_util_fun(res.data, 'langpt_dt') })

export const obter_freguesias_por_cidade = (cidade, set_fun) => load_freguesias_pt(cidade).then(res => select_option_util_fun(res.data, set_fun, 'langpt_fr'))

export const obter_paises_em_pt = set_fun => load_countries_pt().then(res => select_option_util_fun(res.data, set_fun, 'langpt'))

export const obter_distritos_pt = set_fun => load_districts_pt().then(res => select_option_util_fun(res.data, set_fun, 'langpt_dt'))


export const passwordHash = password => crypto.createHash('md5').update(password).digest("hex")


export const pattern_vat =
    '^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'

export const pattern_email = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

export const set_language = (lang) => {
    localStorage.setItem('lang', lang);
}
export const content_language = [
    {
        id: '1',
        code: 'pt'
    },
    {
        id: '2',
        code: 'en'
    }
];

// REPO UTILS
export const repo_base_link= (resource) => Hosts.REPO+"static/assets/"+ resource+"/";

export const repo_site_link= (resource) => Hosts.REPO+"static/site/"+ resource+"/";

export const repo_favicon_link= (resource) => repo_base_link("favicon")+ resource;
export const repo_hero_link= (resource) => repo_base_link("hero")+ resource;
export const repo_logo_link= (resource) => repo_base_link("logo")+ resource;
export const repo_img_link= (resource) => repo_base_link("img")+ resource;
export const repo_banner_link= (resource) => repo_base_link("banner")+ resource;


export const repo_site_assets_link= (resource) => repo_site_link("assets")+ resource;

/*
module.exports = {
    reqGET,
    reqPOST,
    reqPUT,
    reqDELETE,
    reqFILE,

    obter_cidades_por_distrito,
    obter_distrito_por_cidade,
    obter_freguesias_por_cidade,
    obter_paises_em_pt,
    obter_distritos_pt,



    get_vat_info,

    passwordHash,

    pattern_vat,
    pattern_email,

    set_language,

}
*/
import React, { useState, useEffect, useRef } from 'react'
import { useParams, Redirect } from "react-router-dom";

import "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import NormalContent from './Normal';
import WebinarCardsContent from './WebinarCards';
import WebinarContent from './Webinar';
import WorkshopCardsContent from './WebinarCards';
import WorkshopContent from './Workshop';
import SeminarCardsContent from './SeminarCards';
import SeminarContent from './Seminar';
import ConferenceCardsContent from './SeminarCards';
import ConferenceContent from './Conference';
import AtividadesContent from './Activity';


import NotFound from './NotFound';

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';

import './Content.css';

export default function Content() {

    const { pag_id, slug, webinar_id, workshop_id, seminar_id, conference_id } = useParams();
    const [isloading, setLoading] = useState(true);

    const [, set_content] = useState(null);
	const content = useRef(null);

	const [new_slug, set_new_slug] = useState('');

    useEffect(() => {

        let getInfoId = async (id) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }
                        
                        if(res.data[0].info.slug !== '') {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            //slug = res.data[0].info.slug
                            set_new_slug(res.data[0].info.slug)
                            //console.log(navigation)
                        }


                        set_content(content.current = res.data[0])

                        return true
                        //set_content(res.data[0])
                    }
                    else {
                        getInfoSlug(id)
                            .then((res) => {
                                if(res === true){
                                    setLoading(false)
                                }
                            }) 
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getInfoSlug = async (slug) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            res.data[0].info.layout = '404'
                        }
                        set_content(content.current = res.data[0])
                        
                        return true
                    }
                    else {
                        set_content(content.current = {id: '', info: {type: 'content', layout: '404'}, content: []})

                        return true
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        if(pag_id !== undefined && pag_id !== 'undefined' && pag_id !== '') {
            getInfoId(pag_id)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
            getInfoSlug(slug)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(webinar_id !== undefined && webinar_id !== 'undefined' && webinar_id !== '')  {
            if(webinar_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'webinar', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(webinar_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(workshop_id !== undefined && workshop_id !== 'undefined' && workshop_id !== '')  {
            if(workshop_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'workshop', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(workshop_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(seminar_id !== undefined && seminar_id !== 'undefined' && seminar_id !== '')  {
            if(seminar_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'workshop', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(seminar_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(conference_id !== undefined && conference_id !== 'undefined' && conference_id !== '')  {
            if(conference_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'workshop', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(conference_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }


        

    }, [pag_id, slug, webinar_id, workshop_id, seminar_id, conference_id]);

    return (isloading ?

		<></>
		
		:

        (() => {

            if(new_slug !== '' && new_slug === 'atividades') {
                return (
                    <AtividadesContent location={{ state: { id: pag_id, slug: new_slug } }} />
                )
            }
            else {
            
                switch (content.current.info.type) {
                    case 'content':
                        switch (content.current.info.layout) {
                            case '404':
                                return (
                                    <NotFound info={{ state: { id: pag_id, slug: slug } }} />
                                ) 

                            default:
                                return (
                                    <NormalContent info={{ state: { id: pag_id, slug: slug } }} />
                                )
                        }

                    case 'webinar':
                        switch (content.current.info.layout) {
                            case 'all':
                                return (
                                    <WebinarCardsContent info={{ state: { id: webinar_id, slug: slug } }} />
                                )

                            case 'normal':
                                return (
                                    <WebinarContent info={{ state: { id: webinar_id, slug: slug } }} />
                                )
                            
                            case '404':
                                return (
                                    <NotFound info={{ state: { id: webinar_id, slug: slug } }} />
                                ) 

                            default:
                                return (
                                    <NormalContent info={{ state: { id: webinar_id, slug: slug } }} />
                                )
                        }

                    case 'workshop':
                        switch (content.current.info.layout) {
                            case 'all':
                                return (
                                    <WorkshopCardsContent info={{ state: { id: workshop_id, slug: slug } }} />
                                )

                            case 'normal':
                                return (
                                    <WorkshopContent info={{ state: { id: workshop_id, slug: slug } }} />
                                )
                            
                            case '404':
                                return (
                                    <NotFound info={{ state: { id: workshop_id, slug: slug } }} />
                                ) 

                            default:
                                return (
                                    <NormalContent info={{ state: { id: workshop_id, slug: slug } }} />
                                )
                        }

                    case 'seminar':
                        switch (content.current.info.layout) {
                            case 'all':
                                return (
                                    <SeminarCardsContent info={{ state: { id: seminar_id, slug: slug } }} />
                                )

                            case 'normal':
                                return (
                                    <SeminarContent info={{ state: { id: seminar_id, slug: slug } }} />
                                )
                            
                            case '404':
                                return (
                                    <NotFound info={{ state: { id: seminar_id, slug: slug } }} />
                                ) 

                            default:
                                return (
                                    <NormalContent info={{ state: { id: seminar_id, slug: slug } }} />
                                )
                        }

                    case 'conference':
                        switch (content.current.info.layout) {
                            case 'all':
                                return (
                                    <ConferenceCardsContent info={{ state: { id: conference_id, slug: slug } }} />
                                )

                            case 'normal':
                                return (
                                    <ConferenceContent info={{ state: { id: conference_id, slug: slug } }} />
                                )
                            
                            case '404':
                                return (
                                    <NotFound info={{ state: { id: conference_id, slug: slug } }} />
                                ) 

                            default:
                                return (
                                    <NormalContent info={{ state: { id: conference_id, slug: slug } }} />
                                )
                        }

                    default:
                        return <Redirect to="/" />
                }

            }
        })()
    
    )
}

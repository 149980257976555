/** Ficheiro de Configuração de Acessos Remotos */


if (process.env.REACT_APP_MODE === 'production') console.log = function () { };

// ============== PARA ELIMINAR ============== 

const SITES = {
    SIMCONFERENCE: {
        ACCESS_TOKEN: "SIMCONFERENCE;I1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
    },
    SIMWEBSITE: {
        ACCESS_TOKEN: "SIMWEBSITE;1ZXI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
    }
}

//const webSiteURL = '/demo/assec/sim/conference/'

//const emailWebSiteURL = `https://sede.assec.pt${webSiteURL}`

//const backofficeURL = '/demo/assec/sim/conference-gest'

//const emailBackofficeURL = `https://sede.assec.pt${backofficeURL}`

// ============================================ 


const webSiteTitle = 'EU.CEI'

const webSiteAdress = 'Espaço Inovação Mealhada, Av. Cidade de Coimbra nº 51 Sala 1, 3050-374 Mealhada'

const companycode = '0000000';
//const companycode = '0000251';

//const contactFrom = 'web.dev@assec.pt';
const contactFrom = process.env.REACT_APP_API_EMAIL_CONTACT
//const contactFrom = 'geral@eucei.pt';

const site_compete = 'https://www.compete2020.gov.pt/';
const site_aciba = 'https://www.aciba.pt/';
const site_aciff = 'https://www.aciff.pt/';
const site_acistds = 'https://acistds.pt/';

const fbLink = 'https://www.facebook.com/projeto.eu.cei';
const instaLink = 'https://www.instagram.com/eu.cei/';
const linkedinLink = 'https://www.linkedin.com/company/eu-cei';


// ########################### CONFIG ##################################

const FOOTER_EMAIL_COLOR = '#333333'

console.log('HOSTS APPLICATION_URL:', process.env.REACT_APP_PRINCIPAL_URL)

console.log('HOSTS APPLICATION_SITE_URI:', process.env.REACT_APP_PRINCIPAL_URL)
const WEB_SITE_URI = process.env.REACT_APP_SITE_URI

console.log('HOSTS APPLICATION_BACKOFFICE_URI:', process.env.REACT_APP_GEST_URI)
const WEB_BACKOFFICE_URI = process.env.REACT_APP_GEST_URI

console.log('NODE_ENV SIMCore_API:', process.env.REACT_APP_API_URL)
const SIMCore_API = process.env.REACT_APP_API_URL;

console.log('NODE_ENV APP_REPO_FOLDER:', process.env.REACT_APP_REPO_FOLDER)
const APP_REPO_FOLDER = process.env.REACT_APP_REPO_FOLDER

console.log('NODE_ENV REPO URL:', process.env.REACT_APP_REPO_URL)
const REPO = process.env.REACT_APP_REPO_URL

console.log('NODE_ENV SIMCore_API_MAIL:', process.env.REACT_APP_API_EMAIL_URL)
const SIMCore_API_MAIL = process.env.REACT_APP_API_EMAIL_URL

console.log('NODE_ENV EMAIL_WEB_SITE_URL:', process.env.REACT_APP_SITE_URL)
console.log('NODE_ENV EMAIL_WEB_SITE_URL_REAL :', process.env.REACT_APP_SITE_URL_REAL)

const EMAIL_WEB_SITE_URL = process.env.REACT_APP_SITE_URL_REAL

console.log('NODE_ENV EMAIL_WEB_SITE_URL_TEXT:', process.env.REACT_APP_SITE_PRODUCTION)
console.log('NODE_ENV EMAIL_WEB_BACKOFFICE_URL_TEXT :', process.env.REACT_APP_BACKOFFICE_PRODUCTION)
const EMAIL_WEB_SITE_URL_TEXT = process.env.REACT_APP_SITE_PRODUCTION
const EMAIL_WEB_BACKOFFICE_URL_TEXT = process.env.REACT_APP_BACKOFFICE_PRODUCTION

console.log('NODE_ENV EMAIL_WEB_BACKOFFICE_URL:', process.env.REACT_APP_GEST_URL)
console.log('NODE_ENV EMAIL_WEB_BACKOFFICE_URL_REAL:', process.env.REACT_APP_BACKOFFICE_URL_REAL)
const EMAIL_WEB_BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL_REAL



// #####################################################################


//Begin exporting all variables
module.exports = {
    SIMCore_API,
    SIMCore_API_MAIL,

    FOOTER_EMAIL_COLOR,
    REPO,
    APP_REPO_FOLDER,
    WEB_SITE_URI,
    WEB_BACKOFFICE_URI,
    EMAIL_WEB_SITE_URL,
    EMAIL_WEB_BACKOFFICE_URL,

    SITES,
    //backofficeURL,    
    //webSiteURL,
    //emailWebSiteURL,
    //emailBackofficeURL,
    webSiteTitle,
    webSiteAdress,
    companycode,
    contactFrom,
    site_compete,
    site_aciba,
    site_aciff,
    site_acistds,
    fbLink,
    instaLink,
    linkedinLink,
    EMAIL_WEB_SITE_URL_TEXT,
    EMAIL_WEB_BACKOFFICE_URL_TEXT,
};

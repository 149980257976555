import React, { useState, useEffect } from 'react';

import parse from 'html-react-parser';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language, repo_site_assets_link } from '../../../Utils';

import { language } from '../WEBContent/Language';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /*faChevronLeft, faFileDownload,*/ faFilePdf } from "@fortawesome/free-solid-svg-icons";

//import SeminarContent from "./Seminario";

export default function SeminarCards() {

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [list_content, set_list_content] = useState([]);
    const [list_content_2, set_list_content_2] = useState([]);

    const [data, setData] = useState([]); 

    //const [link2, set_link2] = useState('')

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/132`)
            .then(res => { 
                setData(res.data)
                //console.log(res.data)

            })
            .catch(erro => console.log(erro))  

    }

    useEffect(() => {
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        getInfo()

        try {
            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/seminar`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            /*if(res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== '') {
                                const { hostname } = new URL(res.data[0].content[0].online_link.includes('http') ? res.data[0].content[0].online_link : 'http://' + res.data[0].content[0].online_link)
                                set_link2(hostname)
                            }*/

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published)
                    
                                  if(hoje >= date_p) {
                                    if(v.info.date.avaiable !== '') {
                                        var date_a = new Date(v.info.date.avaiable);

                                        if(hoje <= date_a) {
                                            aux.push(v)
                                        }
                                    }
                                    else {
                                        aux.push(v)
                                    }
                                  }
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))

                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/conference`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            /*if(res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== '') {
                                const { hostname } = new URL(res.data[0].content[0].online_link.includes('http') ? res.data[0].content[0].online_link : 'http://' + res.data[0].content[0].online_link)
                                set_link2(hostname)
                            }*/

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                                if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                    var date_p = new Date(v.info.date.published)
                    
                                    if(hoje >= date_p) {
                                        if(v.info.date.avaiable !== '') {
                                            var date_a = new Date(v.info.date.avaiable);

                                            if(hoje <= date_a) {
                                                aux.push(v)
                                            }
                                        }
                                        else {
                                            aux.push(v)
                                        }
                                    }
                                }
                            })
                            set_list_content_2(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                    
                            setLoading(false)
                            }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, []);

    return (isloading ?

		    <></>
		
		:
        <>
            <main className="webinar-cards">
                <section id="webinar-cards"> 
                    <div className="container-fluid">
                        <div className="row">
                            {
                                data.length > 0 ?

                                    <div className='col-12'>
                                        {
                                            data.map((v, key) => { 
                                                return (
                                                <div key={key}>
                                                    <div className="container">
                                                        <h3 className="titulo-primario mb-0">
                                                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                                        </h3>
                                                    </div> 
                                                    <div className="container ck-content">
                                                
                                                        <p style={{color:'var(--cinza-escuro)'}}>
                                                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                                                        </p> 
                                                                    
                                                        {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}

                                                        { v.content[0].media.doc.length > 0 ? (
                                                            <>      
                                                                <label className="font-weight-bold mt-3">{language[lang].more}</label>
                                                                <ul className="list-group mb-5">
                                                                    {
                                                                        (lang !== 'pt' && v.content['language'] !== undefined && v.content['language'].length > 0 && v.content.language[lang_id] !== undefined && v.content.language[lang_id]['content_files'] !== undefined && v.content.language[lang_id]['content_files'].length > 0) ? 
                                                                            v.content[0].media.doc.map((value, key) => {
                                                                            
                                                                                return (                                                        
                                                                                    <li className="list-unstyled" key={key}>
                                                                                        <a className="mr-2" href={value.link} download={value.name} target="_blank" rel="noreferrer">
                                                                                            <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                                            {/* {language[lang].submission_rules_pdf} */}
                                                                                            {value.name}
                                                                                        </a>
                                                                                    </li>
                                                                                    
                                                                                )
                                                                            })
                                                                        :
                                                                            v.content[0].media.doc.map((value, key) => {
                                                                                return (
                                                                                    <li className="list-unstyled" key={key}>
                                                                                        <a className="mr-2" href={value.link} download={value.name} target="_blank" rel="noreferrer">
                                                                                            <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                                            {/* {language[lang].submission_rules_pdf} */}
                                                                                            {value.name}
                                                                                        </a>
                                                                                    </li>
                                                                                    
                                                                                )
                                                                            })

                                                                    }
                                                                </ul>  
                                                            </>                    
                                                        ) : null }        
                                                    
                                                    </div>
                                                </div>
                                                );
                                            })
                                        }

                                        
                                    </div>

                                : null
                            }

                            {
                                list_content.length > 0 ?
                                    <div className="col-12 offset-lg-1 col-lg-10 py-3 py-md-5">
                                        <div className="text-center">
                                            <h3 className="titulo-pp">{language[lang].seminars}</h3>
                                        </div>
                                        
                                        <div className="row">
                                            {
                                            list_content.map((content) => {
                                                return (
                                                    content.content.map((el, k) => {
                                                        return (
                                                            <div key={k} className="col-12 col-md-6 col-lg-4 col-xl-4 mb-5">
                                                                <div className="card new-item hover-animate shadow-sm">
                                                                    <a href={'./seminar/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)} title={language[lang].know_more}>
                                                                        <div className="div-image"> 
                                                                            {
                                                                            el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                <img src={el['media']['image'][0]['link']} className="img-fluid" alt="" />
                                                                            :
                                                                                <img src={'nophoto.png'} className="img-fluid" alt="" />
                                                                            }
                                                                        </div>
                                                                        {
                                                                            content.info['seminar_status'] !== undefined && content.info['seminar_status'] !== '' && content.info['seminar_status'] !== 'scheduled' ?
                                                                                <div className="webinar-badge">
                                                                                    {
                                                                                        content.info['seminar_status'] === 'accomplished' ?
                                                                                            <img alt='badge' className="img-fluid" src={repo_site_assets_link("badge-realizado.svg")} />
                                                                                        : 
                                                                                            <img alt='badge' className="img-fluid" src={repo_site_assets_link("badge-cancelado.svg")} />
                                                                                    }
                                                                                </div>
                                                                            : null
                                                                        }

                                                                    </a>
                                                                    <div className="card-body p-4">
                                                                        {
                                                                            (el.data !== undefined && el.data !== '') || (el.hora !== undefined && el.hora !== '') || (el.hora_fim !== undefined && el.hora_fim !== '') ?
                                                                                <div className="div-source pb-1 d-inline-flex flex-wrap">
                                                                                    {
                                                                                    el.data !== undefined && el.data !== '' ?
                                                                                        <span className='d-flex align-items-center'>
                                                                                            <img alt='calendar' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-calendar.svg")} />
                                                                                            {el.data}
                                                                                        </span>
                                                                                    : null
                                                                                    }
                                                                                    {
                                                                                    el.hora !== undefined && el.hora !== '' ?
                                                                                        <span className='d-flex align-items-center'>
                                                                                            <img alt='clock' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-clock.svg")} />
                                                                                            {el.hora}
                                                                                            {
                                                                                                el.hora_fim !== undefined && el.hora_fim !== '' ?
                                                                                                    <span>&nbsp;&#45;&nbsp;{el.hora_fim}</span>
                                                                                                : null
                                                                                            }
                                                                                        </span>
                                                                                    : null
                                                                                    }
                                                                                </div>
                                                                            : null
                                                                        }
                                                                        {
                                                                            el.location !== undefined && el.location !== '' ?

                                                                                el.location === 'location_online' ?
                                                                                    <div className="div-source pb-1 d-flex align-items-center">
                                                                                        <img alt='clock' className="ico-svg" width="25px" height="25px" src={repo_site_assets_link("icons-local-online.svg")} />
                                                                                        {language[lang][el.location]}
                                                                                    </div>
                                                                                :
                                                                                    (el.location_city !== undefined && el.location_city !== '') || (el.location_place !== undefined && el.location_place !== '') ?
                                                                                        <div className="div-source pb-1 d-flex flex-wrap align-items-center">
                                                                                            <img alt='clock' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-location.svg")} />
                                                                                            {
                                                                                                el.location_city !== undefined && el.location_city !== '' ?

                                                                                                    <span>
                                                                                                        {el.location_city}
                                                                                                    </span>
                                                                                                :

                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                el.location_place !== undefined && el.location_place !== '' ?
                                                                                                    <span className='text-truncate'>{el.location_place}</span>
                                                                                                :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    : 
                                                                                    null
                                                                            :
                                                                                null
                                                                        }
                                                                        {
                                                                            /*el.online_link !== undefined && el.online_link !== undefined ?
                                                                                <div className="div-source pb-1 d-flex">
                                                                                    <img alt='url' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-url.svg")} />
                                                                                    <a href={el.online_link.includes('http') ? el.online_link : 'http://' + el.online_link} alt="url" target="_blank" rel="noreferrer">
                                                                                        {link2}
                                                                                    </a>
                                                                                </div>
                                                                            :
                                                                                null*/
                                                                        } 
                                                                        <div className="card-title mt-2">{el.title}</div>
                                                                        <div className="card-text">
                                                                            { 
                                                                                el.abstract !== undefined && el.abstract !== '' ?

                                                                                    parse(el.abstract)

                                                                                : null
                                                                            }
                                                                        </div> 
                                                                    </div>
                                                                    <div className="px-3 mb-3 text-right">
                                                                        <a className="btn btn-text shadow-sm" href={'./seminar/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)}>{language[lang].know_more}<i className="d-none bi bi-chevron-right"></i></a>
                                                                    </div>

                                                                    <div className="card-footer text-muted d-none">
                                                                    <i className="d-none bi bi-tag pr-2" />
                                                                        {
                                                                        content.info.tags.map((v, k) => {
                                                                            return (
                                                                                <span key={k} className="pill-tag">{v.text}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        )
                                                    })
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                : null
                            }

                            {
                                list_content_2.length > 0 ?
                                    <div className="col-12 offset-lg-1 col-lg-10 py-3 py-md-5">
                                        <div className="text-center">
                                            <h3 className="titulo-pp">{language[lang].conferences}</h3>
                                        </div>
                                        
                                        <div className="row">
                                            {
                                            list_content_2.map((content) => {
                                                return (
                                                    content.content.map((el, k) => {  
                                                        return (
                                                            <div key={k} className="col-12 col-md-6 col-lg-4 col-xl-4 mb-5">
                                                                <div className="card new-item hover-animate shadow-sm">
                                                                    <a href={'./conference/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)} title={language[lang].know_more}>
                                                                        <div className="div-image"> 
                                                                            {
                                                                            el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                <img src={el['media']['image'][0]['link']} className="img-fluid" alt="" />
                                                                            :
                                                                                <img src={'nophoto.png'} className="img-fluid" alt="" />
                                                                            }
                                                                            {
                                                                                content.info['conference_status'] !== undefined && content.info['conference_status'] !== '' && content.info['conference_status'] !== 'scheduled' ?
                                                                                    <div className="webinar-badge">
                                                                                        {
                                                                                            content.info['conference_status'] === 'accomplished' ?
                                                                                                <img alt='badge' className="img-fluid" src={repo_site_assets_link("badge-realizado.svg")} />
                                                                                            : 
                                                                                                <img alt='badge' className="img-fluid" src={repo_site_assets_link("badge-cancelado.svg")} />
                                                                                        }
                                                                                    </div>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </a>
                                                                    <div className="card-body p-4">
                                                                        {
                                                                            (el.data !== undefined && el.data !== '') || (el.hora !== undefined && el.hora !== '') || (el.hora_fim !== undefined && el.hora_fim !== '') ?
                                                                                <div className="div-source pb-1 d-inline-flex flex-wrap">
                                                                                    {
                                                                                    el.data !== undefined && el.data !== '' ?
                                                                                        <span className='d-flex align-items-center'>
                                                                                            <img alt='calendar' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-calendar.svg")} />
                                                                                            {el.data}
                                                                                        </span>
                                                                                    : null
                                                                                    }
                                                                                    {
                                                                                    el.hora !== undefined && el.hora !== '' ?
                                                                                        <span className='d-flex align-items-center'>
                                                                                            <img alt='clock' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-clock.svg")} />
                                                                                            {el.hora}
                                                                                            {
                                                                                                el.hora_fim !== undefined && el.hora_fim !== '' ?
                                                                                                    <span>&nbsp;&#45;&nbsp;{el.hora_fim}</span>
                                                                                                : null
                                                                                            }
                                                                                        </span>
                                                                                    : null
                                                                                    }
                                                                                </div>
                                                                            : null
                                                                        }
                                                                        {
                                                                            el.location !== undefined && el.location !== '' ?

                                                                                el.location === 'location_online' ?
                                                                                    <div className="div-source pb-1 d-flex align-items-center">
                                                                                        <img alt='clock' className="ico-svg" width="25px" height="25px" src={repo_site_assets_link("icons-local-online.svg")} />
                                                                                        {language[lang][el.location]}
                                                                                    </div>
                                                                                :
                                                                                    (el.location_city !== undefined && el.location_city !== '') || (el.location_place !== undefined && el.location_place !== '') ?
                                                                                        <div className="div-source pb-1 d-flex flex-wrap align-items-center ">
                                                                                            <img alt='clock' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-location.svg")} />
                                                                                            {
                                                                                                el.location_city !== undefined && el.location_city !== '' ?

                                                                                                    <span>
                                                                                                        {el.location_city}
                                                                                                    </span>
                                                                                                :

                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                el.location_place !== undefined && el.location_place !== '' ?
                                                                                                    <span className='text-truncate'>{el.location_place}</span>
                                                                                                :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    : 
                                                                                    null
                                                                            :
                                                                                null
                                                                        }
                                                                        {
                                                                            /*el.online_link !== undefined && el.online_link !== undefined ?
                                                                                <div className="div-source pb-1 d-flex">
                                                                                    <img alt='url' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-url.svg")} />
                                                                                    <a href={el.online_link.includes('http') ? el.online_link : 'http://' + el.online_link} alt="url" target="_blank" rel="noreferrer">
                                                                                        {link2}
                                                                                    </a>
                                                                                </div>
                                                                            :
                                                                                null*/
                                                                        } 
                                                                        <div className="card-title mt-2">{el.title}</div>
                                                                        <div className="card-text">
                                                                            { 
                                                                                el.abstract !== undefined && el.abstract !== '' ?

                                                                                    parse(el.abstract)

                                                                                : null
                                                                            }
                                                                        </div> 
                                                                    </div>
                                                                    <div className="px-3 mb-3 text-right">
                                                                        <a className="btn btn-text shadow-sm" href={'./conference/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)}>{language[lang].know_more}<i className="d-none bi bi-chevron-right"></i></a>
                                                                    </div>

                                                                    <div className="card-footer text-muted d-none">
                                                                    <i className="d-none bi bi-tag pr-2" />
                                                                        {
                                                                        content.info.tags.map((v, k) => {
                                                                            return (
                                                                                <span key={k} className="pill-tag">{v.text}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        )
                                                    })
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}
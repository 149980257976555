import React, { useState, useEffect } from 'react';

import parse from 'html-react-parser';

import * as Hosts from '../../../Hosts';
import { 
	reqGET,
    content_language
} from '../../../Utils';

import { language } from '../WEBContent/Language';

export default function Consultoria() {

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })
        
        try {
			let slug = '';
            let id = '131'

            if(id !== '' || slug !== '') {
                let getInfoId = async (id) => {
                    return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(res.data[0].info.preview === true) {
                                    set_content(res.data[0])
                                    // setLoading(false)   
                                    return true
                                }
                                else if(status !== 2) {
                                    res.data[0].content = [{text: ''}]
        
                                    set_content(res.data[0])
                                    // setLoading(false)   
                                    return true 
                                }
                                else {
                                    set_content(res.data[0]) 
                                    // setLoading(false) 
                                    return true
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async (slug) => {
                    return await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: ''}]
        
                                    set_content(res.data[0])
                                    // setLoading(false)
                                    return true
                                }
                                else {
                                    set_content(res.data[0]) 
                                    // setLoading(false) 
                                    return true
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId(id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug(slug)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        

    }, []);

    return (isloading ?

		<></>

		:

        <>
            <main className="mt-3 mb-3 mb-md-5">
                {
                content !== undefined ?
                    content.content.map((el, k) => {  
                        return (
                            <section id="activity_consultoria" key={k} className="content-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 div-content">
                                            {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                    <div className="div-image w-100"> 
                                                        <img src={el['language'][lang_id]['media']['image'][0]['link']} className="img-fluid" alt={el['language'][lang_id]['media']['image'][0]['name']} />
                                                    </div>
                                                :

                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                        <div className="div-image w-100 mb-3 mb-md-5 mt-3">
                                                            <img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />
                                                        </div>
                                                    : 
                                                        null 
                                            }

                                            {
                                                <div className="div-content my-3">
                                                    <h1 className={el.title !== '' ? "titulo-primario mt-3" : "d-none"}>
                                                        {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                    </h1>
                                                    { 
                                                        el.subtitle !== '' ?
                                                            <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                            </h2>
                                                        :
                                                            ''
                                                    }
                                                    { 
                                                        el.text !== '' ?
                                                            <div className="div-text">
                                                                {parse(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                            </div>
                                                        :
                                                            ''
                                                    }

                                                    {
                                                        el.content_link !== undefined && el.content_link !== '' ?

                                                            <div className='my-4'>
                                                                
                                                                <a className='btn btn-inscription shadow-sm' href={el.content_link.includes('http') ? el.content_link : 'http://' + el.content_link} alt="" target="_blank" rel="noreferrer">
                                                                    {language[lang].btn_consultoria_inscription} <i className="bi bi-chevron-double-right ml-2" />
                                                                </a>
                                                            </div> 
                                                        :

                                                            null
                                                    }
                                                </div>
                                            }

                                            {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 ?
                                                    <div className="div-content my-3">
                                                        <div className="row div-gallery">
                                                            {
                                                            el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-2">
                                                                        <figure>
                                                                            <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                            <figcaption>{el_img.description}</figcaption>
                                                                        </figure>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ? (
                                                        <div className="div-content my-3">
                                                            <div className="row div-gallery">
                                                                {
                                                                el.media.image.slice(1).map((el_img, k_img) => {
                                                                    return (
                                                                        <div key={k_img} className="col-2">
                                                                            <figure>
                                                                                <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                                <figcaption>{el_img.description}</figcaption>
                                                                            </figure>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : null 
                                            }
                                            {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                    <div className="div-content my-3">
                                                        <div className="row div-documents">
                                                            {
                                                            el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                return (
                                                                    <div key={k_doc} className="col-12 mb-2">
                                                                        {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                        
                                                                        <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                            {el_doc.name}
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ? (
                                                        <div className="div-content my-3">
                                                            <div className="row div-documents">
                                                                {
                                                                el.media.doc.map((el_doc, k_doc) => {
                                                                    return (
                                                                        <div key={k_doc} className="col-12 mb-2">
                                                                            {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                            
                                                                            <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                {el_doc.name}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : null 
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                :
                    null
                }
            </main>
        </>
    )
}
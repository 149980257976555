import React, { useState, useEffect, useRef } from 'react';
import {Button } from "reactstrap";
//import Footer from "../Footer/Footer";

import TextareaCounter from 'react-textarea-counter';

import './Forms.css';
import parse from 'html-react-parser';

import Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
	reqPOST,
    repo_banner_link,
    content_language

} from '../../../Utils';


import { language } from '../WEBContent/Language';

import Swal from 'sweetalert2';

const ContactForm = () => {


    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');
    const [data, setData] = useState([]);   

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/7`)
            .then(res => { 
                setData(res.data)
                //console.log(res.data)

            })
            .catch(erro => alert('Erro'))  

    }    
    useEffect(() => { 
        getInfo()
        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else 
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
      }

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,
            name: '',
            email: '',
            message: '',
            rgpd: false,    
        }

    }

    const [ , set_dados ] = useState( null ) ;
    const dados = useRef( data_interface ) ;


    const handle_change_info = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info[name] = value)
        //console.log('Dados Current:' + JSON.stringify(dados))
    }
    
   /*  const handleSubmit = async e => {
        e.preventDefault();

        //alert('SUBMIT');
        //return;
        
        reqPOST(`${Hosts.SIMCore_API}/entities`, dados.current)
            .then(() => {   
                alert('Registo adicionado!')
                //props.history.push(endpoints.list) 
            })
            .catch(() => alert('Problema ao atualizar Registo!'))
    } */


    let mailOptions = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: dados.current.info.email,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        cc: Hosts.contactFrom,
        subject: Hosts.webSiteTitle + ' - ' + language[lang].contact_form,
        //text: 'Teste de EMAIL ', -> opcional
        html:

            '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
                '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
                    '<tr style="border-collapse: collapse;">' +
                        '<td style="border-collapse: collapse;">' +
                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                                        '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '" target="_blank">' +
                                            '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                                        '</a>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                            '<table width="540" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 540px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
                                        '<div style="width:540px; margin: 0 auto; padding-left: 30px; padding-right: 30px;">'+
                                            '<h4 style="text-transform: uppercase;">'+ language[lang].contact_form +':</h4>'+
                                            '<span><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                                            '<br/>'+
                                            '<span><b>Email: </b><label>' + dados.current.info.email + '</label></span>'+
                                            '<br/>'+
                                            '<span><b>'+ language[lang].subject +': </b><label>' + dados.current.info.subject + '</label></span>' +
                                            '<br/>'+
                                            '<span><b>'+ language[lang].message +': </b><label>' + dados.current.info.message + '</label></span>' +
                                            '<br/>'+
                                        '</div>'+
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
                                '<tr style="border-collapse: collapse;">' +
                                    '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
                                        '<div style="margin: 0 auto; width:600px;">'+
                                            '<div style="text-align: center; font-size: small; color: #FFFFFF;">'+
                                                '<span style="text-transform: uppercase;">' + Hosts.webSiteTitle + '</span>'+
                                                '<br/>'+
                                                '<span>' + Hosts.webSiteAdress + '</span>'+
                                                '<br/>'+
                                                '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL_TEXT + '" target="_blank" style="text-decoration:none;"> '+
                                                    '<span style="color: #FFFFFF;" >'+ Hosts.EMAIL_WEB_SITE_URL_TEXT +'</span>' +
                                                '</a>'+
                                            '</div>'+
                                        '</div>' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                        '</td>' +
                    '</tr>' +
                '</table>' +
            '</div>',

    }; 

    const handleSubmit = async e => {
        e.preventDefault();

        //alert('SUBMIT');
        //return;

        reqPOST(`${Hosts.SIMCore_API_MAIL}`, {data: mailOptions})
            .then(() => { 
                //console.log('Notificações enviadas com sucesso') 
                set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                document.getElementById('agree').checked = false
                //setAgree(false)
                //setRefresh(refresh + 1)
                Swal.fire({
                    title: language[lang].contact_form,
                    text: language[lang].sent_successfully,
                    icon: 'success',
                    //showCancelButton: true,
                    confirmButtonColor: '#08396a',
                    //confirmButtonText: 'Tentar novamente',
                  })
        
            })
            .catch((error) => console.log(error)/*alert('Problema ao enviar notificações!')*/)

    }

        
    return(
        <div>
            <section className="margemBody">                    
                        
                {/* <div className="container-fluid bg-color-blue">
                    <div className="container py-4 mb-4">
                        <h3 className="titulo-primario mb-0 text-white">{language[lang].contacts}</h3>
                    </div>              
                </div> */}

                {/* <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe width="100%" height="500" id="gmap_canvas" src={"https://maps.google.com/maps?q=Centro%20Hospitalar%20Universit%C3%A1rio%20Cova%20da%20Beira,%20EPE,%20Alameda%20P%C3%AAro%20da%20Covilh%C3%A3,%206200-251%20Covilh%C3%A3&t=&z=15&ie=UTF8&iwloc=&output=embed"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        
                    </div>
                </div> */}

                {data.map((v, key) => { 
                    return (
                    <div key={key}>
                        <div className="container my-5">                                     
                            <p className="titulo-secundario">{lang === 'pt' || v.content.language === undefined ? v.content.secondary_title : (v.content.language[lang_id] === undefined || v.content.language[lang_id].secondary_title === undefined || v.content.language[lang_id].secondary_title === '' ? v.content.secondary_title :v.content.language[lang_id].secondary_title)}</p>                                   
                            {parse(lang === 'pt' || v.content.language === undefined ? v.content.text : (v.content.language[lang_id] === undefined || v.content.language[lang_id].text === undefined || v.content.language[lang_id].text === '' ? v.content.text :v.content.language[lang_id].text))}                             
                        </div>
                    </div>
                    );
                })}

                <div className="container">
                    <form onSubmit={handleSubmit}>                                                

                        <div className="row">
                            <div className="col-12">
                                <p>
                                    {language[lang].read_terms_conditions}
                                    {/*Lê os nossos <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">termos e condições</a> antes de submeteres o formulário. Ao submeteres concordas com os termos do nosso serviço.*/}
                                </p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <label>{language[lang].name}*</label>
                                <input type="text" name="name" value={dados.current.info.name} className="form-control" placeholder="" onChange={handle_change_info} required />
                            </div>
                            <div className="col-12 col-sm-6">
                                <label>{language[lang].email}*</label>
                                <input type="email" name="email" 
                                    value={dados.current.info.email} 
                                    className="form-control" 
                                    placeholder="" 
                                    onChange={handle_change_info} required
                                /> 
                            </div>
                            <div className="col-12 mt-2">
                                <label>{language[lang].subject}*</label>
                                <input type="subject" name="subject" 
                                    value={dados.current.info.subject} 
                                    className="form-control" 
                                    placeholder="" 
                                    onChange={handle_change_info} required
                                /> 
                            </div>                            
                        </div>

                        <div className="row">
                            <div className="col-12 mt-2">
                                <label>{language[lang].message}*</label>
                                <TextareaCounter countLimit={600} style={{height: "100px", width: "100%"}} id="message" name="message" value={dados.current.info.message} onChange={handle_change_info} required/>
                                {/* <input type="text" name="message" value={dados.current.info.message} className="form-control" placeholder="" onChange={handle_change_info} /> */}
                            </div>
                            <div className="col-12">
                                <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
                                <label className="pl-2" htmlFor="agree">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
                                <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                            </div>
                        </div>                            
                    
                        <div className="text-right">
                            {/*{console.log('AGREE2', agree)}
                            {console.log('MAIL: ', JSON.stringify(mailOptions))}*/}
                            <Button disabled={!agree} className="mt-5 mx-auto" color="primary">{language[lang].send}</Button>     
                        </div>                              

                    </form>
                </div>
                    
            </section>
            {/* <Footer /> */}
        </div>
    );


};

export default ContactForm;
import React, { useState, useEffect } from 'react';
import './Slider.css';
import '../Pagina.css';

//import { language } from '../WEBContent/Language';

import { reqGET, set_language, content_language } from '../../../Utils';

import * as Hosts from "../../../Hosts";

//import parse from 'html-react-parser';

import { Navigation, Pagination, Autoplay, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import "swiper/swiper-bundle.min.css";

import { language } from '../WEBContent/Language';

//import BaseName from "../BaseName";

//export default class Slider extends React.Component {
const Slider = () => {

    const [lang, set_lang] = useState('pt');
    const [banner, setBanner] = useState([]);
    const [lang_id, set_lang_id] = useState('1');

    /* 
        const change_lang = (l) => {
            set_language(l)
            set_lang(l)
    
            //console.log('language', localStorage.getItem('lang'))
            //console.log('localstorage', localStorage)
        } */

    let getInfo = async () => {
        //setLoading(true)
        reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/banner`)
            .then(res => {
                //setBanner(res.data)
                //console.log('BANNER', res.data)

                if (res.data.length > 0) {

                    var hoje = new Date();
                    let aux = []
                    res.data.forEach((v) => {
                      if(v.info.date.published !== undefined && v.info.date.published !== '') {
                        var date_p = new Date(v.info.date.published);

                        if((hoje >= date_p)) {
                            if(v.info.date.avaiable !== undefined && v.info.date.avaiable !== '') { 
                                var date_a = new Date(v.info.date.avaiable);
                                if(hoje <= date_a) {
                                    aux.push(v)
                                }
                            } else{
                                aux.push(v)
                            }
                        }

                          //aux.push(v)
                      }
                    })
                    setBanner(aux)
                    //set_list_content(res.data)
                    //console.log('NOTICIAS', res.data)
          
                    //setLoading(false)
                }
                
                //setLoading(false)
                //calculo_margem()
            })
            .catch(erro => console.log('Erro', erro))
    }

    useEffect(() => {
        getInfo()

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }


        /*   if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
              change_lang('pt')
          }
          else {
              set_lang(localStorage.getItem('lang'))
          } */

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)


    }, [])


    //render() {

    return (

        <div>
            {/*<section id="slider" className="d-none">
                <div className="swiper-container slideshow">
                    <div className="swiper-wrapper">
                        {banner.map((v, key) => {
                            return (
                                <div key={key} className="swiper-slide slide">
                                    <div className={"slide-image slide-image-" + key} style={{ backgroundImage: "url(" + v.content[0].media.image[0].link + ")" }}>
                                        <span className="slide-title caption_centro">
                                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                            <a className="btn btn-primary d-none" href={Hosts.WEB_BACKOFFICE_URI} target="_blank" rel="noreferrer">
                                                {language[lang].submit_article}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
                <div className="col-12">
                    <div className="slideshow-pagination"></div>
                </div>
            </section>
            */}

            <section id="slider">
                <div className="swiper swiper-container swiper-cartaz slideshow">
                    <Swiper
                        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectFade]}
                        spaceBetween={0}
                        slidesPerView={1}
                        //navigation={banner.length > 1 ? true : false}
                        navigation={{
                            nextEl: '.swiper-cartaz-button-next',
                            prevEl: '.swiper-cartaz-button-prev',
                        }}
                        loop={false}
                        speed={2500}
                        autoplay={{
                            delay: 7000,
                            disableOnInteraction: false
                        }}
                        effect="fade"
                        //pagination={banner.length > 1 ? true : false}
                        pagination={{
                            el: '.swiper-pagination-cartaz',
                            clickable: true
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 1,
                                //navigation: true,
                            },
                            1200: {
                                slidesPerView: 1,
                                //navigation: true,
                                //loop: true,
                            },
                        }}
                    /*scrollbar={{ draggable: true }}*/
                    //onSwiper={(swiper) => console.log(swiper)}
                    //onSlideChange={() => console.log('slide change')}
                    >
                        {banner.map((v, key) => {

                            //console.log(v)

                            //check if link has http (if not, add it to link)
                            if(v.content[0].connection.link !== '' && v.content[0].connection.link.substr(0, 4) !== 'http') {
                                v.content[0].connection.link = 'http://' + v.content[0].connection.link;
                                //console.log(v.content[0].connection.link)

                            }

                            let aux_type = ''
                            if(v.content[0].connection['type'] !== undefined && v.content[0].connection['type'] !== ''){

                                if(v.content[0].connection['type'] === 'webinar' || v.content[0].connection['type'] === 'workshop'|| v.content[0].connection['type'] === 'seminar' || v.content[0].connection['type'] === 'conference'){
                                    aux_type = v.content[0].connection['type'] + '/'
                                    //console.log(aux_type)
                                }

                            }

                            return (
                                
                                (!v.info.button && (v.content[0].connection.link !== '' || v.content[0].connection.id !== '')) ?
                                    <div key={key}>
                                        <SwiperSlide key={key} className={"slide-image slide-image-" + key} style={{ backgroundImage: "url(" + v.content[0].media.image[0].link + ")" }}>
                                        {
                                                v.content[0].connection.link !== '' ?

                                                    <a href={v.content[0].connection.link} target="_blank" rel="noreferrer" className="d-block h-100 w-100">
                                                
                                                        {
                                                            v.content[0].display_text !== undefined && v.content[0].display_text === true ?
                                                                <span className={"slide-title caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                    <h3 className="cartaz-titulo">
                                                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                                                    </h3>
                                                                    <h3 className="cartaz-subtitulo">
                                                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                                                    </h3>
                                                                </span>
                                                            : null
                                                        }
                                                    </a>
                                                : 
                                                    <a href={Hosts.WEB_SITE_URI + (aux_type !== '' ? aux_type + v.content[0].connection.id : (v.content[0].connection.id !== '130' && v.content[0].connection.id !== '165'  ? 'pag/' + v.content[0].connection.id : 'concurso_resultados'))} /*target="_blank" rel="noreferrer"*/ className="d-block h-100 w-100">
                                                
                                                        {
                                                            v.content[0].display_text !== undefined && v.content[0].display_text === true ?
                                                                <span className={"slide-title caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                    <h3 className="cartaz-titulo">
                                                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                                                    </h3>
                                                                    <h3 className="cartaz-subtitulo">
                                                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                                                    </h3>
                                                                </span>
                                                            : null
                                                        }
                                                    </a>
                                            }
                                            
                                        </SwiperSlide>
                                    </div>

                                :
                                    <div key={key}>
                                        <SwiperSlide key={key} className={"slide-image slide-image-" + key} style={{ backgroundImage: "url(" + v.content[0].media.image[0].link + ")" }}>
                                                    
                                        {
                                            v.content[0].display_text !== undefined && v.content[0].display_text === true ?       
                                                <span className={"slide-title caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                    <h3 className="cartaz-titulo">
                                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                                    </h3>
                                                    {
                                                        v.content[0].subtitle !== '' ?
                                                            <h3 className="cartaz-subtitulo">
                                                                {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                                            </h3>
                                                        : null
                                                    }
                                                    {
                                                        v.content[0].connection.link !== '' ?
                                                            <a href={v.content[0].connection.link} target="_blank" rel="noreferrer" title={language[lang].know_more} alt={language[lang].know_more} className="btn btn-primary my-4">
                                                                {language[lang].know_more}
                                                            </a>
                                                        : null
                                                    }
                                                    {
                                                        
                                                        v.content[0].connection.id !== '' ?
                                                            <a href={Hosts.WEB_SITE_URI + (aux_type !== '' ? aux_type + v.content[0].connection.id : (v.content[0].connection.id !== '130' && v.content[0].connection.id !== '165'  ? 'pag/' + v.content[0].connection.id : 'concurso_resultados'))} /*target="_blank" rel="noreferrer"*/ title={language[lang].know_more} alt={language[lang].know_more} className="btn btn-primary my-4">
                                                                {language[lang].know_more}
                                                            </a>
                                                        : null
                                                    }
                                                </span>
                                            : null
                                        }

                                            
                                        </SwiperSlide>
                                    </div>
                            )
                        })
                        }

                        <div className="col-12 d-none d-lg-block">
                            <section id="slider-btn" className="demo mt-5">
                                <span></span>
                            </section>
                        </div>

                        <div className="swiper-pagination-cartaz mb-3"></div>
                        <div className={"swiper-button-next swiper-cartaz-button-next " + (banner.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>
                        <div className={"swiper-button-prev swiper-cartaz-button-prev " + (banner.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>

                        <div className="col-12 d-none">
                            <section id="slider-btn" className="demo mt-5">
                                <span></span>
                            </section>
                        </div>

                    </Swiper>
                </div>
            </section>
        </div>
    )
    //}

    //}
};
export default Slider;
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import './Footer.css';

import { language } from '../WEBContent/Language';

import { set_language, /*repo_logo_link,*/ repo_site_assets_link } from '../../../Utils';

import Hosts from '../../../Hosts';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = () => {

  const [lang, set_lang] = useState('pt');

  const change_lang = (l) => {
    set_language(l)
    set_lang(l)

    //console.log('language', localStorage.getItem('lang'))
    //console.log('localstorage', localStorage)
  }

  useEffect(() => {

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      change_lang('pt')
    }
    else {
      set_lang(localStorage.getItem('lang'))
    }

  }, [])


  const page_location = useLocation().pathname.split('/').pop();

  return (
    <footer className="bg-color-body">
      <div className="container-fluid">
        <div className="container">
          <div className="row py-5 py-sm-5">
            {/* <div className="col-12 mb-4">
              <img src={repo_logo_link("logo-branco.svg")} width="180px" height="75px" alt={Hosts.webSiteTitle} className="img-fluid" />
            </div> */}

            <div className="col-12 col-md-4 text-white mt-md-4">
              <span className="address">
                <h6 className="mb-3">{parse(language[lang].footer_address_1)}</h6>
              </span>
              <span className="address">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("localizacao.svg")} />
                <p>{parse(language[lang].footer_address_11)}</p>
              </span>
              <span className="address">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("telefone.svg")} />
                <p>{language[lang].footer_address_12}</p>&nbsp;(Chamada para a rede fixa nacional)
              </span>
              <span className="address mb-4">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("email.svg")} />
                <a href={'mailto:' + language[lang].footer_address_13}>{language[lang].footer_address_13}</a>
              </span>
            </div>
            <div className="col-12 col-md-4 text-white mt-md-4">
              <span className="address">
                <h6 className="mb-3">{parse(language[lang].footer_address_2)}</h6>
              </span>
              <span className="address">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("localizacao.svg")} />
                <p>{parse(language[lang].footer_address_21)}</p>
              </span>
              <span className="address">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("telefone.svg")} />
                <p>{language[lang].footer_address_22}</p>&nbsp;(Chamada para a rede fixa nacional)
              </span>
              <span className="address mb-4">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("email.svg")} />
                <a href={'mailto:' + language[lang].footer_address_23}>{language[lang].footer_address_23}</a>
              </span>
            </div>
            <div className="col-12 col-md-4 text-white mt-md-4">
              <span className="address">
                <h6 className="mb-3">{parse(language[lang].footer_address_3)}</h6>
              </span>
              <span className="address">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("localizacao.svg")} />
                <p>{parse(language[lang].footer_address_31)}</p>
              </span>
              <span className="address">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("telefone.svg")} />
                <p>{language[lang].footer_address_32}</p>&nbsp;(Chamada para a rede fixa nacional)
              </span>
              <span className="address mb-4">
                <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("email.svg")} />
                <a href={'mailto:' + language[lang].footer_address_33}>{language[lang].footer_address_33}</a>
              </span>
            </div>

            <div className="col-12 col-md-4 mt-md-4 text-white">
              <span className="mb-4">{language[lang].footer_logos_01}</span>
              <a href={Hosts.site_compete} title={Hosts.site_compete} alt={Hosts.site_compete} target="_blank" rel="noreferrer" >
                <img src={repo_site_assets_link("logos-01.svg")} width="" height="" alt={Hosts.site_compete} className="img-fluid" />
              </a>
            </div>
            <div className="col-12 col-md-4 mt-md-4 text-white">
              <span className="mb-4">{language[lang].footer_logos_02}</span>
              <span className="logosfooter">
                <a href={Hosts.site_aciba} title={Hosts.site_aciba} alt={Hosts.site_aciba} target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("aciba.svg")} width="" height="" alt={Hosts.site_aciba} className="img-fluid" />
                </a>
                <a href={Hosts.site_aciff} title={Hosts.site_aciff} alt={Hosts.site_aciff} target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("aciff.svg")} width="" height="" alt={Hosts.site_aciff} className="img-fluid" />
                </a>
                <a href={Hosts.site_acistds} title={Hosts.site_acistds} alt={Hosts.site_acistds} target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("acistds.svg")} width="" height="" alt={Hosts.site_acistds} className="img-fluid" />
                </a>
              </span>
            </div>
            <div className="col-12 col-md-4 mt-md-4 text-white">
              <span className="mb-4">{language[lang].footer_logos_03}</span>
              <span className="logosfooter redes">
                <a href={Hosts.fbLink} title={Hosts.fbLink} alt={Hosts.fbLink} target="_blank" rel="noreferrer" >
                  {/* <FontAwesomeIcon className="ml-2" icon={facebook} /> */}
                  <img src={repo_site_assets_link("facebook.svg")} width="" height="" alt={Hosts.fbLink} className="img-fluid" />
                </a>
                <a href={Hosts.instaLink} title={Hosts.instaLink} alt={Hosts.instaLink} target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("instagram.svg")} width="" height="" alt={Hosts.instaLink} className="img-fluid" />
                </a>
                <a href={Hosts.linkedinLink} title={Hosts.linkedinLink} alt={Hosts.linkedinLink} target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("linkedin.svg")} width="" height="" alt={Hosts.linkedinLink} className="img-fluid" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 text-center pt-2 pb-2 justify-content-center text-white">
          <ul className="list-inline mb-0 copyright">
            <li className="list-inline-item p-0 mr-0">
              <span>
                <i className="far fa-copyright fa-fw"></i> 2022 {language[lang].reserved_rights}
              </span>
            </li>
            <li className="list-inline-item p-0 mr-0">
              <span className="px-2">&#124;</span>
              <span>
                <a className={"text-white " + (page_location === 'terms_conditions' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "terms_conditions"}>
                  {language[lang].terms_conditions}
                </a>
              </span>
              <span className="px-2">&#124;</span>
            </li>
            <li className="list-inline-item p-0">
              <span>{language[lang].developed}</span>
            </li>
            <li className="list-inline-item p-0">
              <span>
                <a href="http://sim.assec.pt" title="Assec Sim!" target="_blank" rel="noreferrer">
                  {/*<img className="img-fluid" src="./img/logo.png" alt="Assec Sim!" style={logoAssec}/>*/}
                  Assec Sim!
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </footer>

  )

};
export default Footer;
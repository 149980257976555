import React, { useState, useEffect } from 'react';


import parse from 'html-react-parser';


import Footer from "../Footer/Footer";
import "../Pagina.css";
import "../content-styles.css"

import Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
    content_language

} from '../../../Utils';

import Navbar from "../Navbar/Navbar";
import AppForm from "../Forms/AppForm";
import AppForm2 from "../Forms/AppForm2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /*faChevronLeft, faFileDownload,*/ faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { language } from '../WEBContent/Language';

//import regulamentoData from './dadosRegulamento';

const Concurso = () => {

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [data, setData] = useState([]);   

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/101`)
            .then(res => { 
                setData(res.data)
                //console.log(res.data)

            })
            .catch(erro => alert('Erro'))  

    }
    
    useEffect(() => { 
        getInfo()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 



    return (
        <>
            <Navbar mostrar_botao_voltar={true} />
            <section className="margemBody mt-100">           

                {data.map((v, key) => { 
                    return (
                    <div key={key}>
                        <div className="container-fluid bg-color-gray-light">
                            <div className="container py-4 mb-4">
                                <h3 className="titulo-primario mb-0">
                                    {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                </h3>
                            </div>              
                        </div>
                        <div className="container mt-5 ck-content">
                                            
                    
                            <p style={{color:'var(--cinza-escuro)'}}>
                                {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
                            </p> 
                                        
                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}

                            { v.content[0].media.doc.length > 0 ? (
                                <>      
                                    <label className="font-weight-bold mt-3">{language[lang].more}</label>
                                    <ul className="list-group mb-5">
                                        {
                                            (lang !== 'pt' && v.content['language'] !== undefined && v.content['language'].length > 0 && v.content.language[lang_id] !== undefined && v.content.language[lang_id]['content_files'] !== undefined && v.content.language[lang_id]['content_files'].length > 0) ? 
                                                v.content[0].media.doc.map((value, key) => {
                                                   
                                                    return (                                                        
                                                        <li className="list-unstyled" key={key}>
                                                            <a className="mr-2" href={value.link} download={value.name} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                {/* {language[lang].submission_rules_pdf} */}
                                                                {value.name}
                                                            </a>
                                                        </li>
                                                        
                                                    )
                                                })
                                            :
                                                v.content[0].media.doc.map((value, key) => {
                                                    return (
                                                        <li className="list-unstyled" key={key}>
                                                            <a className="mr-2" href={value.link} download={value.name} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                {/* {language[lang].submission_rules_pdf} */}
                                                                {value.name}
                                                            </a>
                                                        </li>
                                                        
                                                    )
                                                })

                                        }
                                    </ul>  
                                </>                    
                            ) : null }        
                        
                        </div>
                    </div>
                    );
                })}

                <section id="tabs" className="project-tab mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav>
                                    <div className="nav nav-tabs nav-fill " id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link w-50 pb-3 pb-md-4 align-self-end active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                                            <h4 className="m-2">{language[lang].tipo1}</h4>
                                            <div  style={{color: 'var(--body)', fontWeight: 400, fontSize: 'small'}}>{language[lang].tipo1_txt}</div>
                                        </a>
                                        <a className="nav-item nav-link w-50 pb-3 pb-md-4 align-self-end" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                                            <h4 className="m-2">{language[lang].tipo2}</h4>
                                            <div  style={{color: 'var(--body)', fontWeight: 400, fontSize: 'small'}}>{language[lang].tipo2_txt}</div>
                                        </a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <AppForm />
                                    </div>
                                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <AppForm2 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
                <Footer />
            </section>
        </>
      
    );

};
export default Concurso;


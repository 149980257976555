import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import parse from 'html-react-parser';

import Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
    content_language,
    repo_site_assets_link

} from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

import { language } from '../WEBContent/Language';

export default function Workshop(props) {

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    //const [link, set_link] = useState('')
    const [link2, set_link2] = useState('')

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;

            if(id !== '') {
        
                let getInfoList = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/workshop`)
                        .then(res => { 
                            if (res.data.length > 0) {
    
                                var hoje = new Date();
                                let aux = []
                                res.data.forEach((v, k) => {
                                  if(v.info.date.published !== undefined && v.info.date.published !== '' && aux.length <= 2) {
                                      var date_p = new Date(v.info.date.published);
                        
                                      if(hoje >= date_p) {
                                        if(v.info.date.avaiable !== '') {
                                            var date_a = new Date(v.info.date.avaiable);
    
                                            if(hoje <= date_a) {
                                                aux.push(v)
                                            }
                                        }
                                        else {
                                            aux.push(v)
                                        }
                                      }
                                  }
                                })
                                set_list_content(aux)
                              }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
                getInfoList()

                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {

                                /*if(res.data[0].content[0].website !== undefined && res.data[0].content[0].website !== undefined && res.data[0].content[0].website !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].website.includes('http') ? res.data[0].content[0].website : 'http://' + res.data[0].content[0].website)
                                    set_link(hostname)
                                }*/

                                if(res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].online_link.includes('http') ? res.data[0].content[0].online_link : 'http://' + res.data[0].content[0].online_link)
                                    set_link2(hostname)
                                }

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {

                                /*if(res.data[0].content[0].website !== undefined && res.data[0].content[0].website !== undefined && res.data[0].content[0].website !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].website.includes('http') ? res.data[0].content[0].website : 'http://' + res.data[0].content[0].website)
                                    set_link(hostname)
                                }*/

                                if(res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== undefined && res.data[0].content[0].online_link !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].online_link.includes('http') ? res.data[0].content[0].online_link : 'http://' + res.data[0].content[0].online_link)
                                    set_link2(hostname)
                                }
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info.state.id, props.info.state.slug]);

    return (isloading ?

		    <></>

		:

        <>
            <Navbar mostrar_botao_voltar={true} />
                {
                content.content.map((el, k) => {  
                    return (
                        <section id="activity_webinar" key={k} className="activity-webinar content-body mt-100">
                            <div className="container-fluid bg-color-gray-light">
                                <div className="container py-4">
                                    <h3 className="titulo-primario mb-0">
                                        {language[lang].btn_workshop}
                                    </h3>
                                </div>              
                            </div>
                            <div className="container">
                                <div className="row py-5">
                                    <div className="div-content col-12 col-lg-8 pr-md-3 pr-lg-5">
                                        {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                            <div className={"div-image mb-3 mb-md-5"}>
                                                <img src={el['media']['image'][0]['link']} className="img-fluid shadow-sm" alt="" />
                                            </div>
                                        :
                                            null
                                        }
                                        <div className={"div-title"}>
                                            <h1 className={el.title !== '' ? "titulo-primario" : "d-none"}>{el.title}</h1>
                                        </div>

                                        {
                                            (el.data !== undefined && el.data !== '') || (el.hora !== undefined && el.hora !== '') ||  (el.hora_fim !== undefined && el.hora_fim !== '') ?
                                                <div className="div-source pb-1 d-inline-flex">
                                                    {
                                                    el.data !== undefined && el.data !== '' ?
                                                        <span className='d-flex align-items-center'>
                                                            <img alt='calendar' className="mr-2" width="20px" height="20px" src={repo_site_assets_link("icons-local-calendar.svg")} />
                                                            {el.data}
                                                        </span>
                                                    : null
                                                    }
                                                    {
                                                    el.hora !== undefined && el.hora !== '' ?
                                                        <span className='d-flex align-items-center'>
                                                            <img alt='clock' className="mr-2" width="20px" height="20px" src={repo_site_assets_link("icons-local-clock.svg")} />
                                                            {el.hora}
                                                            {
                                                                el.hora_fim !== undefined && el.hora_fim !== '' ?
                                                                    <span>&nbsp;&#45;&nbsp;{el.hora_fim}</span>
                                                                : null
                                                            }
                                                        </span>
                                                    : null
                                                    }
                                                </div>
                                            : null
                                        }
                                        {
                                            el.location !== undefined && el.location !== '' ?

                                                el.location === 'location_online' ?
                                                    <div className="div-source pb-1 d-flex">
                                                        <img alt='clock' className="mr-2" width="25px" height="25px" src={repo_site_assets_link("icons-local-online.svg")} />
                                                        {language[lang][el.location]}
                                                    </div>
                                                :
                                                    (el.location_city !== undefined && el.location_city !== '') || (el.location_place !== undefined && el.location_place !== '') ?
                                                        <div className="div-source pb-1 d-flex flex-wrap align-items-center">
                                                            <img alt='clock' className="mr-2" width="20px" height="20px" src={repo_site_assets_link("icons-local-location.svg")} />
                                                            {
                                                                el.location_city !== undefined && el.location_city !== '' ?

                                                                    <span>
                                                                        {el.location_city}
                                                                    </span>
                                                                :

                                                                    null
                                                            }
                                                            {
                                                                el.location_place !== undefined && el.location_place !== '' ?
                                                                    <span>{el.location_place}</span>
                                                                :
                                                                    null
                                                            }
                                                        </div>
                                                    : 
                                                    null
                                            :
                                                null
                                        }


                                        {
                                            el.online_link !== undefined && el.online_link !== '' ?
                                                <div className="div-source pb-1 d-flex align-items-center">
                                                    <img alt='url' className="mr-2" width="20px" height="20px" src={repo_site_assets_link("icons-local-url.svg")} />
                                                    <a href={el.online_link.includes('http') ? el.online_link : 'http://' + el.online_link} alt="url" target="_blank" rel="noreferrer">
                                                        {link2}
                                                    </a>
                                                </div>
                                            :
                                                null
                                        }

                                        
                                        {
                                            el.website !== undefined && el.website !== '' ?

                                                <div className='my-4'>
                                                    
                                                    <a className='btn btn-inscription shadow-sm' href={el.website.includes('http') ? el.website : 'http://' + el.website} alt="" target="_blank" rel="noreferrer">
                                                        {language[lang].btn_webinars_inscription} <i className="bi bi-chevron-double-right ml-2" />
                                                    </a>
                                                </div> 
                                            :

                                                null
                                        }
                                        { 
                                             el.abstract !== undefined && el.abstract !== '' ?

                                                    <div className={"div-abstract my-3"}>
                                                        {parse(el.abstract)}
                                                        <hr />
                                                    </div>
                                            :
                                                ''
                                        }

                                        <div className="div-categories w-100 d-none"><i className="bi bi-tag" />
                                            {
                                            content.info.tags.map((v, k) => {
                                                return (
                                                    <span key={k} className="pill-tag">{v.text}</span>
                                                )
                                            })
                                            }
                                        </div>

                                        <div className={"div-source d-none"}>
                                            {
                                            content.info.date !== undefined && content.info.date['published'] !== '' ?
                                                <span>
                                                    {content.info.date['published'].replace("T", " ")}
                                                </span>
                                            :
                                                ''
                                            }
                                            
                                            {
                                            content.info.source !== undefined && content.info.source['text'] !== undefined ? 
                                                <span>
                                                    {language[lang].source_text}
                                                </span> 
                                            : 
                                                ''
                                            }

                                            {
                                            content.info.source !== undefined && content.info.source['image'] !== undefined && content.info.source['image'] !== '' ? 
                                                <span>
                                                    {language[lang].source_image}
                                                    {
                                                    content.info.source !== undefined && content.info.source['image'] !== '' ? 
                                                        content.info.source['image'] 
                                                    : 
                                                        ''
                                                    }
                                                </span>
                                            : 
                                                ''
                                            }
                                            
                                        </div>
                                        
                                        <div className={"div-text my-3"}>
                                            {parse(el.text)}
                                        </div>

                                        {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                            <div className="row">
                                                <div className="div-gallery col-12 mb-5">
                                                    {
                                                    el.media.image.map((el_img, k_img) => {
                                                        if(k_img > 0) {
                                                            return (
                                                                <div key={k_img} className="col-2">
                                                                    <figure>
                                                                        <img className="img-fluid shadow-sm" src={el_img.link} alt={el_img.description} />
                                                                        <figcaption>{el_img.description}</figcaption>
                                                                    </figure>
                                                                </div>
                                                            )
                                                        }
                                                        else 
                                                            return null
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            null
                                        }
                                        {
                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                            <div className="row">
                                                <div className="div-documents col-12 mb-5">
                                                    <label className="font-weight-bold">{language[lang].more}</label>
                                                    {
                                                    el.media.doc.map((el_doc, k_doc) => {
                                                        return (
                                                            <div key={k_doc} className="mb-2">
                                                                <a href={el_doc.link} target="_blank" rel="noreferrer">
                                                                    <i className="bi bi-file-pdf mr-2"></i>
                                                                    {el_doc.name}
                                                                </a>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            null
                                        }
                                    </div>

                                    <div className="div-last webinar-cards col-12 col-lg-4 mb-5 mt-md-5 mt-lg-0">
                                        <div className='row'>
                                            <div className='col-12 text-center mb-4'>
                                                <Link className="btn btn-text-inv shadow-sm" to={{ pathname: '/atividades', state: { active: 'webinar' } }} replace title={language[lang].btn_webinars_all}>
                                                    <i className="bi bi-chevron-left" />
                                                    {language[lang].btn_webinars}
                                                </Link>

                                            </div> 
                                            <div className='col-12 d-none'>
                                                <h3 className='subtitulo-pp'>{language[lang].btn_webinar}</h3>
                                            </div>
                                            {
                                            list_content.map((content_list) => {
                                                return (
                                                    content_list.content.map((el, k) => {  
                                                        if(content_list.id !== content.id) {
                                                            return (
                                                                <div key={k} className="col-12">
                                                                    <div className="mb-3 insight-item hover-animate shadow-sm">
                                                                        <a href={'./workshop/' + (content_list.info['slug'] !== undefined && content_list.info.slug !== '' ? content_list.info.slug : content_list.id)} title={el.title}>
                                                                            <div className="div-image d-none d-md-block">
                                                                                {
                                                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                    <img src={el['media']['image'][0]['link']} className="img-fluid" alt="" />
                                                                                :
                                                                                    <img src={'nophoto.png'} className="img-fluid" alt="" />
                                                                                }
                                                                            </div>
                                                                            <div className="p-0 p-md-3">
                                                                                {
                                                                                    (el.data !== undefined && el.data !== '') || (el.hora !== undefined && el.hora !== '') || (el.hora_fim !== undefined && el.hora_fim !== '') ?
                                                                                        <div className="div-source pb-1 d-inline-flex flex-wrap">
                                                                                            {
                                                                                            el.data !== undefined && el.data !== '' ?
                                                                                                <span className='d-flex align-items-center'>
                                                                                                    <img alt='calendar' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-calendar.svg")} />
                                                                                                    {el.data}
                                                                                                </span>
                                                                                            : null
                                                                                            }
                                                                                            {
                                                                                            el.hora !== undefined && el.hora !== '' ?
                                                                                                <span className='d-flex align-items-center'>
                                                                                                    <img alt='clock' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-clock.svg")} />
                                                                                                    {el.hora}
                                                                                                    {
                                                                                                        el.hora_fim !== undefined && el.hora_fim !== '' ?
                                                                                                            <span>&nbsp;&#45;&nbsp;{el.hora_fim}</span>
                                                                                                        : null
                                                                                                    }
                                                                                                </span>
                                                                                            : null
                                                                                            }
                                                                                        </div>
                                                                                    : null
                                                                                }
                                                                                {
                                                                                    el.location !== undefined && el.location !== '' ?

                                                                                        el.location === 'location_online' ?
                                                                                            <div className="div-source pb-1 d-flex">
                                                                                                <img alt='clock' className="ico-svg" width="25px" height="25px" src={repo_site_assets_link("icons-local-online.svg")} />
                                                                                                {language[lang][el.location]}
                                                                                            </div>
                                                                                        :
                                                                                            (el.location_city !== undefined && el.location_city !== '') || (el.location_place !== undefined && el.location_place !== '') ?
                                                                                                <div className="div-source pb-1 d-flex flex-wrap align-items-center">
                                                                                                    <img alt='clock' className="ico-svg" width="20px" height="20px" src={repo_site_assets_link("icons-local-location.svg")} />
                                                                                                    {
                                                                                                        el.location_city !== undefined && el.location_city !== '' ?

                                                                                                            <span>
                                                                                                                {el.location_city}
                                                                                                            </span>
                                                                                                        :

                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        el.location_place !== undefined && el.location_place !== '' ?
                                                                                                            <span className='text-truncate'>{el.location_place}</span>
                                                                                                        :
                                                                                                            null
                                                                                                    }
                                                                                                </div>
                                                                                            : 
                                                                                            null
                                                                                    :
                                                                                        null
                                                                                }
                                                                                <div className="div-source d-none">
                                                                                    <span>{parse(content_list.info.date.published.split('T')[0])}</span>
                                                                                </div>
                                                                                <div>
                                                                                    {el.title}
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        else 
                                                            return null
                                                    })
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            <Footer />
        </>
    )
}
import React, { useState, useEffect } from 'react';


import parse from 'html-react-parser';


import Footer from "../Footer/Footer";
import "../Pagina.css";
import "../content-styles.css"

import Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
    content_language

} from '../../../Utils';

import Navbar from "../Navbar/Navbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /*faChevronLeft, faFileDownload,*/ faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { language } from '../WEBContent/Language';


const ConcursoResultados = () => {

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [isloading, setLoading] = useState(true);

    const [data, setData] = useState([]);   

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/165`)
            .then(res => { 
                setData(res.data[0])
                setLoading(false)
                //console.log(res.data[0])

            })
            .catch(erro => alert('Erro'))  

    }
    
    useEffect(() => { 
        getInfo()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 



    return (isloading ?

		<></>

		:

        <>
            <Navbar mostrar_botao_voltar={true} />
            <section className="margemBody mt-100">           

                {
                    data !== undefined ?
                        data.content.map((el, k) => {  
                            return (
                                <div key={k}>
                                    <div className="container-fluid bg-color-gray-light">
                                        <div className="container py-4 mb-4">
                                            <h3 className="titulo-primario mb-0">
                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                            </h3>
                                        </div>              
                                    </div>
                                    <div className="container my-5">

                                        <div className="row">
                                            <div className="col-12 div-content">
                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                        <div className="div-image w-100"> 
                                                            <img src={el['language'][lang_id]['media']['image'][0]['link']} className="img-fluid" alt={el['language'][lang_id]['media']['image'][0]['name']} />
                                                        </div>
                                                    :

                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                            <div className="div-image w-100 mb-3 mb-md-5 mt-3">
                                                                <img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />
                                                            </div>
                                                        : 
                                                            null 
                                                }

                                                {
                                                    <div className="div-content my-3">
                                                        <h1 className={el.title !== '' ? "titulo-primario mt-3 d-none" : "d-none"}>
                                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                        </h1>
                                                        { 
                                                            el.subtitle !== '' ?
                                                                <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                    {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                </h2>
                                                            :
                                                                ''
                                                        }
                                                        { 
                                                            el.text !== '' ?
                                                                <div className="div-text">
                                                                    {parse(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                                </div>
                                                            :
                                                                ''
                                                        }

                                                        {
                                                            el.content_link !== undefined && el.content_link !== '' ?

                                                                <div className='my-4'>
                                                                    
                                                                    <a className='btn btn-inscription shadow-sm' href={el.content_link.includes('http') ? el.content_link : 'http://' + el.content_link} alt="" target="_blank" rel="noreferrer">
                                                                        {language[lang].btn_consultoria_inscription} <i className="bi bi-chevron-double-right ml-2" />
                                                                    </a>
                                                                </div> 
                                                            :

                                                                null
                                                        }
                                                    </div>
                                                }

                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 ?
                                                       
                                                        <div className="row div-gallery my-3">
                                                            {
                                                            el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-2">
                                                                        <figure>
                                                                            <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                            <figcaption>{el_img.description}</figcaption>
                                                                        </figure>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                        
                                                    :
                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ? (
                                                            
                                                            <div className="row div-gallery my-3">
                                                                {
                                                                el.media.image.slice(1).map((el_img, k_img) => {
                                                                    return (
                                                                        <div key={k_img} className="col-2">
                                                                            <figure>
                                                                                <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                                <figcaption>{el_img.description}</figcaption>
                                                                            </figure>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                            
                                                        ) : null 
                                                }
                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                       
                                                        <div className="row div-documents my-3">
                                                            <label className="font-weight-bold mt-3">{language[lang].more}</label>
                                                            {
                                                                el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                    return (
                                                                        <div key={k_doc} className="col-12 mb-2">
                                                                            {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                            
                                                                            <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                                {el_doc.name}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        
                                                    :
                                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ? (
                                                            <div className="row div-documents my-3">
                                                                <label className="font-weight-bold mt-3">{language[lang].more}</label>
                                                                {
                                                                    el.media.doc.map((el_doc, k_doc) => {
                                                                        return (
                                                                            <div key={k_doc} className="col-12 mb-2">
                                                                                {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                                
                                                                                <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                    <FontAwesomeIcon className="font-weight-small" icon={faFilePdf} fixedWidth />
                                                                                    {el_doc.name}
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        ) : null 
                                                }
                                            </div>
                                        </div>        
                                    
                                    </div>
                                </div>
                            );
                        })
                    :
                        null
                }

                <Footer />
            </section>
        </>
      
    );

};
export default ConcursoResultados;


const language = {
    'pt': 
        {
            abstract:           'Resumo', 
            accept_terms:       'Aceito os termos e condições.',
            access_data:        'Dados de acesso',
            adress:             'Morada', 
            advice:             'Os conteúdos utilizados na elaboração desta plataforma são meramente ilustrativos, tendo sido retirados (na sua maioria) do website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> Estes conteúdos são licenciados por autores e editoras para serem livremente utilizados e adaptados.',
            agree_terms_conditions: 'Concordo com os ', 
            article:            'Artigo',
            articles_submission:'Submissão de artigos',
            author_regist:      'Registo de autor', 
            authors:            'Autores',  
            app_form_title:     'Formulário de Inscrição no Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Outros',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Estado de maturidade da ideia',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Qual(is) os  problema(s) que pretendes resolver?',
            app_form_title_06:  'Previsão do número de postos de trabalho diretos e indiretos',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Motivação do(s) promotor(es) e seu envolvimento na concretização da ideia do negócio',
            app_form_title_15:  'Previsões',
            app_form_title_16:  'Ficheiros',
            tipo1:              'Crescer a Empreender',
            tipo1_txt:          'Concurso de Ideias para estudantes do 3º Ciclo e Ensino Secundário, oriundos das regiões Centro e Alentejo.',
            tipo2:              'Empreender a Inovar',
            tipo2_txt:          'Concurso de Ideias para estudantes do Ensino Superior e Empreendedores/as com idade igual ou superior a 18 anos.',
            tipo2_txt2:         'Para os detentores de uma ideia de negócio que pretendam ver concretizada nos Concelhos da NUT II do Centro e Alentejo.',
            backhome:           'Página inicial',
            back:               'Voltar',
            banner_submission:  'Submete os teus artigos',
            banner_submission_2:'Conhece os próximos períodos de submissão e todas as informações necessárias.',
            banner_article_submission_text:  'Para submeteres os teus artigos faz login/registo e junta-te a nós!',
            bibliography:       'Bibliografia',
            biography:          'Biografia',
            btn_conference:        'Conferências',
            btn_conferences:       'Seminários e Conferências',
            btn_conferences_all:   'Lista de Seminários e Conferências',
            btn_conferences_inscription:   'Faz a tua inscrição ',
            btn_seminar:        'Seminários',
            btn_seminars:       'Seminários e Conferências',
            btn_seminars_all:   'Lista de Seminários e Conferências',
            btn_seminars_inscription:   'Faz a tua inscrição ',
            btn_webinar:        'Webinars',
            btn_webinars:       'Webinars e Workshops',
            btn_webinars_all:   'Lista de Webinars e Workshops',
            btn_webinars_inscription:   'Faz a tua inscrição ',
            btn_workshop:       'Workshops',
            btn_workshops:      'Webinars e Workshops',
            btn_workshops_all:  'Lista de Webinars e Workshops',
            btn_workshops_inscription:  'Faz a tua inscrição ',
            btn_consultoria_inscription:  'Preenche a Ficha e faz a tua inscrição ',
            cancel:             'Cancelar',
            cfp_status_closed:  'fechado',
            cfp_status_ending:  'a terminar',
            cfp_status_open:    'aberto',
            cfp_status_soon:    'brevemente',
            change_password_expiration_message:    'O código para alterares a tua palavra-passe expirou. Faz um novo pedido por favor.',
            change_password_sucess_message:    'Palavra-passe atualizada com sucesso',
            close:              'Fechar',
            code:               'Código',
            conferences:        'Conferências',
            confirmation_password_error_message: 'Password de confirmação diferente da password inserida',
            contest:            'Concurso',
            contacts:           'Contactos',  
            contact:            'Contacto',  
            contact_email:      'Email de contacto',   
            contact_form:       'Formulário de contacto',  
            contact_recovery:   'Formulário de recuperação',  
            content:            'Conteúdo',
            content_edit:       'Editar conteúdo',
            content_new:        'Novo conteúdo',
            content_list:       'Lista de conteúdos',
            cookies_message:    '<strong>ATENÇÃO:</strong> Este site utiliza cookies. Ao navegares no site estás a consentir a sua utilização.',
            cookie_button_text : 'Aceitar',
            country:            'País',
            cv_file_01:         'Currículo vitae do 1º candidato',
            cv_file_02:         'Currículo vitae do 2º candidato',
            cv_file_03:         'Currículo vitae do 3º candidato',
            dates:              'Datas',
            date_birth:         'Data de nascimento',
            date_creation:      'Criação',
            date_publication:   'Publicação',
            date_last_update:   'Última actualização',
            declaration_file:   'Declaração de compromisso',
            delete:             'Eliminar',
            delete_title:       'Eliminar Registo',
            delete_text:        'Tens a certeza que pretendes continuar?',
            delete_confirm:     'Sim',
            delete_cancel:      'Não',
            delete_error:       'Problema ao apagar registo!',
            delete_success:     'Registo apagado com sucesso!',
            description:        'Descrição',
            developed:          'Desenvolvido por',
            discover:           'Descobrir',
            download_full_edition:           'Descarregar edição completa',
            designation:        'Designação da ideia/projeto',
            edit:               'Editar',
            edit_title:         'Editar Registo',
            edit_text:          'Tens a certeza que pretendes continuar?',
            edit_confirm:       'Sim',
            edit_cancel:        'Não',
            edit_error:         'Problema ao actualizar registo!',
            edit_success:       'Registo actualizado com sucesso!',
            edition:            'Edição',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contest:      'Recebemos a tua candidatura, vamos analisar e entraremos em contacto com a maior brevidade possível.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_contest_03:   'Recebeu uma nova candidatura para o concurso de ideias.',
            email_contest_new_application: 'Nova candidatura',
            email_contest_button: 'Ver candidatura',
            email_placeholder:  'Introduza o email',
            email_subject_01:    'Concurso de Ideias - Nova Candidatura',
            end:                'Fim',
            enter_country_residence:  'Introduz o país de residência',
            entrepreneur_information:   'Informação de Apoio ao Empreendedor',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'Ficheiro(s)',
            filter_placeholder: 'Procurar',
            footer_address:     'Rua Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',
            footer_address_1:   'ACIBA – Associação Comercial e Industrial da Bairrada e Aguieira',
            footer_address_11:   'Espaço Inovação Mealhada,<br/>Av. Cidade de Coimbra nº 51 Sala 1,<br/>3050-374 Mealhada',
            footer_address_12:   '231 201 606',
            footer_address_13:   'geral@aciba.pt',
            footer_address_2:   'ACIFF - Associação Comercial e Industrial da Figueira da Foz, Associação Empresarial Regional',
            footer_address_21:   'Largo Professor Vítor Guerra n.º 3,<br/>3080-072 Figueira da Foz',
            footer_address_22:   '233 401 320',
            footer_address_23:   'geaciff@aciff.pt',
            footer_address_3:   'ACISTDS - Associação do Comércio, Indústria, Serviços e Turismo do Distrito de Setúbal',
            footer_address_31:   'Delegação - Santiago do Cacém <br/>Avenida D. Nuno Álvares Pereira, n.º 45, 1.º Esq., <br/>7540-101 Santiago do Cacém',
            footer_address_32:   '269 822 128',
            footer_address_33:   'geral@acistds.pt',
            footer_logos_01:    'Cofinanciado por:',
            footer_logos_02:    'Promovido por:',
            footer_logos_03:    'Redes Sociais:',
            form_autor_register: 'Formulário de registo de autor',
            full_edition:       'Edição completa',
            go_platform:        'Ir para a plataforma',
            hello:              'Olá',
            id:                 'ID',
            image:              'Imagem',
            image_choose:       'Escolher imagem',
            info_banner_text:   'Todas as informações necessárias para começar',
            insert_title:       'Novo Registo',
            insert_text:        'Tens a certeza que pretendes continuar?',
            insert_confirm:     'Sim',
            insert_cancel:      'Não',
            insert_error:       'Problema ao inserir registo!',
            insert_success:     'Registo inserido com sucesso!',
            learn_more:         'Saber mais',
            location:           'Localização',
            location_online:           'Online',
            location_onsite:           'Presencial',
            login:              'Iniciar sessão',
            name:               'Nome',
            name_placeholder:   'Introduz o nome',
            maximum_numbers_of_caracters:   'Número máximo de caracteres',
            message:            'Mensagem',
            message_placeholder:    'Introduz a mensagem',
            more:               'Mais informação:',
            cookie_more:        'Mais informação.',
            multimedia:         'Multimédia',
            not_found:          'Página inicial',
            not_found1:          'Oops! Nada foi encontrado',
            not_found2:          'A página que procura pode ter sido removida, ter o seu nome alterado ou estar temporariamente indisponível. ',
            not_found3:          'Voltar à página inicial. ',
            observations:       'Observações',
            opinion_banner_button:   'Deixa a tua opinião',
            opinion_banner_title:   'Deixa-nos a tua opinião',
            opinion_banner_text:    'A tua opinião conta.',
            organization:       'Organização',
            open_file:          'Ver ficheiro',
            opinion_form:       'Formulário de opinião',
            page_config:        'Configuração da Página',
            page_parent:        'Parent',
            pages:              'Páginas',
            password:           'Palavra-passe',
            password_new:       'Nova palavra-passe',
            password_conf:      'Confirmação da palavra-passe',
            platforms:          'Plataformas',
            platforms_banner_text:  'Conhece as plataformas que temos para ti',
            previsions_0:       'Rubricas',
            previsions_1:       'Vendas',
            previsions_2:       'Custos',
            previsions_3:       'Resultados',
            principal:          'Principal',
            privacy_policy:     'Política de privacidade',
            profile_image:      'Imagem de perfil',
            project:            'Projeto',
            project_banner_text:    'Fica a conhecer todo o nosso projeto',
            project_idea_file:  'Apresentação do projeto em PowerPoint',
            qualifications:     'Habilitações',
            read_article:       'Ler artigo',
            read_terms_conditions: 'Lê os nossos termos e condições antes de submeteres o formulário.', 
            regist:             'Registo',
            regist_form_sucess_message: 'Registo efetuado com sucesso. Consulta os teus dados de acesso no email enviado.',
            register:           'Regista-te',
            registry_defined:   'Definida no registo',
            remove:             'Remover',
            reserved_rights:    'Todos os direitos reservados',
            reviewers:          'Revisores',
            //rules:              'Normas de submissão',
            save:               'Salvar', 
            scientia_magazine:  'Revista Científica',
            search:             'Pesquisar', 
            search_by_article_name:             'Pesquisa por nome de artigo', 
            search_by_author_name:             'Pesquisa por nome de autor', 
            search_by_article_keyword:             'Pesquisa por keywords', 
            search_error_message:             'Preenche um dos campos de pesquisa.',
            search_error_noresults_message:             'Não foi encontrado nenhum resultado.',
            seminars:            'Seminários',
            send:               'Enviar',   
            sent_successfully:  'Enviado com sucesso',
            seo_config:         'Configuração SEO',
            seo_description:    'Descrição SEO',
            seo_description_placeholder: 'Introduz a descrição SEO',
            seo_keywords:       'Keywords SEO',
            seo_keywords_small: '(separar por vírgula)',
            seo_keywords_placeholder: 'Introduz as keywords SEO',
            seo_title:          'Título SEO',
            seo_title_placeholder: 'Introduza o título SEO',
            slide_title:        'Juntos partilhamos conhecimento',
            start:              'Início',
            subject:            'Assunto',
            submission:         'Submissão',
            submission_rules:   'Normas de submissão',
            submission_rules_pdf:   'Descarregar instruções',
            submission_rules_pdf_view:   'Ver normas de submissão',
            submit:             'Submeter',
            submit_article:     'Submeter artigo',
            surname:            'Apelido',
            table_rows_per_page:'Registos por página:',
            table_separator:    'de',
            table_all:          'Todos',
            table_title:        'Título',
            table_parent:       'Parent',
            table_date:         'Data',
            table_state:        'Estado',
            table_actions:      'Acções',
            technical_team:     'Corpo técnico',
            terms_conditions:   'Termos e condições',          
            terms_conditions_link:   'termos e condições.',
            terms_conditions_required: 'Os campos assinalados com (*) são de preenchimento obrigatório. O endereço de email permite um contacto mais rápido em caso de necessidade de esclarecimentos adicionais. A informação que submeteres não será usada ou distribuída por outra razão que não o processamento do teu pedido.',
            testimonials:       'Testemunhos',
            text:               'Texto',
            title:              'Título',
            title_placeholder:  'Introduz o título',
            user:               'Utilizador',
            username_error_message:  'Já existe um registo com este username.',
            web_page:           'Página Web',
            webinar:            'Webinars',
            workshop:           'Workshops',
            keep_reading:       'Continue a ler',
            know_more:          'Sabe mais',
            atividade_1:        'Consultoria e Mentoria',
            atividade_1_txt:    '',
            atividade_2:        'Webinars e Workshops',
            atividade_2_txt:    '',
            atividade_3:        'Seminários e Conferências',
            atividade_3_txt:    '',
            atividade_4:        'Estudos e Manuais de Apoio',
            atividade_4_txt:    '',
            atividade_5:        'Divulgação de Resultados',
            atividade_5_txt:    '',
        },
    'en':
        {
            abstract:           'Abstract',
            accept_terms:       'I accept the terms and conditions.',
            access_data:        'Access data',
            adress:             'Adress',
            advice:             'The contents used in the elaboration of this platform are merely illustrative, having been taken (mostly) from the website: <a href="https://open.umn.edu/opentextbooks/" target="_blank">https://open.umn.edu/opentextbooks/</a><br> These contents are licensed by authors and publishers to be freely used and adapted.',
            agree_terms_conditions:   'I agree to ',
            article:            'Article',
            articles_submission:'Article Submission',
            author_regist:      'Author regist', 
            authors:            'Authors',
            app_form_title:     'Formulário de Inscrição Concurso de Ideias',
            app_swal_title:     'Formulário de Inscrição',
            app_form_title_01:  'Identificação do(s) promotor(es)',
            app_form_title_02:  'Setor de atividade',
            app_form_option_02_01:  'Turismo',
            app_form_option_02_03:  'Serviços',
            app_form_option_02_04:  'Saúde',
            app_form_option_02_05:  'Comércio',
            app_form_option_02_06:  'Indústria',
            app_form_option_02_07:  'Ambiente',
            app_form_option_02_08:  'Energia',
            app_form_option_02_09:  'Outros',
            app_form_option_02_10:  'Agricultura',
            app_form_title_03:  'Estado de maturidade da ideia',
            app_form_option_03_01:  'Ideia',
            app_form_option_03_02:  'Pré-projeto',
            app_form_option_03_03:  'Projeto',
            app_form_option_03_04:  'Protótipo',
            app_form_title_04:  'Breve descrição da ideia/projeto',
            app_form_title_05:  'Problema(s) de que pretende resolver',
            app_form_title_06:  'Previsão do número de postos de trabalho diretos e indiretos',
            app_form_title_07:  'Qual a solução?',
            app_form_title_08:  'Quais os aspetos diferenciadores?',
            app_form_title_09:  'Caracterização do mercado',
            app_form_title_10:  'Descrição da estratégia de desenvolvimento da empresa',
            app_form_title_11:  'Descrição de fatores críticos de sucesso do negócio',
            app_form_title_12:  'Quais os parceiros?',
            app_form_title_13:  'Descrição do investimento',
            app_form_title_14:  'Motivação do(s) promotor(es) e seu envolvimento na concretização da ideia do negócio',
            app_form_title_15:  'Previsões',
            app_form_title_16:  'Ficheiros',
            tipo1:              'Crescer a Empreender',
            tipo1_txt:          'Para estudantes do 3º Ciclo e Ensino Secundário, oriundos das regiões Centro e Alentejo',
            tipo2:              'Empreender a Inovar',
            tipo2_txt:          'Para estudantes do Ensino Superior e Empreendedores/as, com idade igual ou superior a 18 anos, detentores de uma ideia de negócio que pretendam ver concretizada nos Concelhos da NUT II do Centro e Alentejo',
            backhome:           'Homepage',
            back:               'Go back',
            banner_submission:  'Submit your articles',
            banner_submission_2:  'Learn about the next submission periods and all the necessary information.',
            banner_article_submission_text:  'To submit your articles login/register and join us!',
            bibliography:       'Bibliography',
            biography:          'Biography',
            btn_conference:        'Conferências',
            btn_conferences:       'Seminários e Conferências',
            btn_conferences_all:   'Lista de Seminários e Conferências',
            btn_conferences_inscription:   'Faz a tua inscrição ',
            btn_seminar:        'Seminários',
            btn_seminars:       'Seminários e Conferências',
            btn_seminars_all:   'Lista de Seminários e Conferências',
            btn_seminars_inscription:   'Faz a tua inscrição ',
            btn_webinar:        'Webinars',
            btn_webinars:       'Webinars e Workshops',
            btn_webinars_all:   'Lista de Webinars e Workshops',
            btn_webinars_inscription:   'Faz a tua inscrição ',
            btn_workshop:       'Workshops',
            btn_workshops:      'Webinars e Workshops',
            btn_workshops_all:  'Lista de Webinars e Workshops',
            btn_workshops_inscription:  'Faz a tua inscrição ',
            btn_consultoria_inscription:  'Preenche a Ficha e faz a tua inscrição ',
            cancel:             'Cancel',
            cfp_status_closed:  'closed',
            cfp_status_ending:  'ending',
            cfp_status_open:    'open',
            cfp_status_soon:    'soon',
            change_password_expiration_message:    'The code to change your password has expired, please make a new request.',
            change_password_sucess_message:    'Password updated successfully',
            close:              'Close',
            code:               'code',
            conferences:        'Conferences',
            confirmation_password_error_message:               'Confirmation password different from the password entered',
            contest:            'Contest',
            contacts:           'Contacts', 
            contact:            'Contact', 
            contact_email:      'Contact Email', 
            contact_form:       'Contact form', 
            contact_recovery:   'Recovery form',  
            content:            'Content',
            content_edit:       'Edit content',
            content_new:        'New content',
            content_list:       'Content list',
            cookies_message:    '<strong>ATTENTION:</strong> This site uses cookies. By using this site, you agree to the use of cookies.',
            cookie_button_text : 'Accept',
            country:            'Country',
            cv_file_01:         'Currículo vitae do 1º candidato',
            cv_file_02:         'Currículo vitae do 2º candidato',
            cv_file_03:         'Currículo vitae do 3º candidato',
            dates:              'Dates',
            date_birth:         'Data de nascimento',
            date_creation:      'Creation',
            date_publication:   'Publication',
            date_last_update:   'Last update',
            declaration_file:   'Declaração de compromisso',
            delete:             'Delete',
            delete_title:       'Delete Regist',
            delete_text:        'Are you sure you want to continue?',
            delete_confirm:     'Yes',
            delete_cancel:      'No',
            delete_error:       'Problem deleting the element!',
            delete_success:     'Element deleted successfully!',
            description:        'Description',
            developed:          'Developed by',
            discover:           'Discover',
            download_full_edition:           'Download full edition',
            designation:        'Name of the idea/project',
            edit:               'Edit',
            edit_title:         'Edit Regist',
            edit_text:          'Are you sure you want to continue?',
            edit_confirm:       'Yes',
            edit_cancel:        'No',
            edit_error:         'Problem updating element!',
            edit_success:       'Successful updated element!',
            edition:            'Edition',
            email:              'Email',
            email_02:           'Email do responsável',
            email_contest:      'Recebemos a tua candidatura, iremos analisar e entraremos em contacto com a maior brevidade possível.',
            email_contest_02:   'Obrigada pela tua participação.',
            email_contest_03:   'Recebeu uma nova candidatura para o concurso de ideias.',
            email_contest_new_application: 'New application',
            email_contest_button: 'See application',
            email_placeholder:  'Insert the email',
            email_subject_01:    'Concurso de Ideias - Nova Candidatura',
            end:                'End',
            enter_country_residence:  'Enter country of residence',
            entrepreneur_information:   'Entrepreneur support information',
            file_attach:        'Attach file',
            file_remove_all:    'Remove all',
            file_upload:        'Upload file',
            file_upload_all:    'Upload all',
            files:              'File(s)',
            filter_placeholder: 'Search',
            footer_address:     'Street Dr. António Plácido da Costa, 16 c/v, 6200-051 Covilhã<p></p>Tfl (+351) 275 310 651/2<p></p>Fax (+351) 275 310 659',
            footer_address_1:   'Street Lorem ipsum dolor sit amet, consectetur adipiscing eli',
            footer_address_2:   '275 000 000',
            footer_address_3:   'email@email.com',
            footer_logos_01:    'Cofinanciado por:',
            footer_logos_02:    'Promovido por:',
            form_autor_register: 'Author registration form',
            full_edition:       'Full edition',
            go_platform:        'Go to the platform',
            hello:              'Hello',
            id:                 'ID',
            image:              'Image',
            image_choose:       'Choose imagem',
            info_banner_text:   'All the information you need to get started',
            insert_title:       'New Regist',
            insert_text:        'Are you sure you want to continue?',
            insert_confirm:     'Yes',
            insert_cancel:      'No',
            insert_error:       'Problem updating element!',
            insert_success:     'Successful updated element!',
            learn_more:         'Learn more',
            location:           'Location',
            location_online:           'Online',
            location_onsite:           'On-site',
            login:              'Login',
            maximum_numbers_of_caracters:   'Maximum number of characters',
            message:            'Message',
            message_placeholder:'Insert the message',
            more:               'More info:',
            cookie_more:        'More indo.',
            multimedia:         'Multimedia',
            name:               'Name',
            name_placeholder:   'Insert the name',
            observations:       'Observations',
            opinion_banner_button:   'Leave opinion',
            opinion_banner_title:   'Leave us your opinion',
            opinion_banner_text:    'Your opinion also counts',
            organization:       'Organization',
            open_file:          'Open file',
            opinion_form:       'Opinion form',
            page_config:        'Page config',
            page_parent:        'Parent',
            pages:              'Pages',
            password:           'Password',
            password_new:       'New password',
            password_conf:      'Password confirmation',
            platforms:          'Platforms',
            platforms_banner_text:  'Get to know the platforms we have for you',
            previsions_0:       'Rubricas',
            previsions_1:       'Vendas',
            previsions_2:       'Custos',
            previsions_3:       'Resultados',
            principal:          'Principal',
            privacy_policy:     'Privacy Policy',
            profile_image:      'Profile image',
            project:            'Project',
            project_banner_text:    'Find out about our entire project',
            project_idea_file:  'Apresentação do projeto em PowerPoint',
            qualifications:     'Qualifications',
            read_article:       'Read article',
            read_terms_conditions: 'Please read our terms and conditions before submitting the form.', 
            regist:             'Register',
            regist_form_sucess_message: 'Registration successful. Check your access data in the email sent.',
            register:           'Register',
            registry_defined:   'Defined in the registry',
            remove:             'Remove',
            reserved_rights:    'All rights reserved',
            reviewers:          'Reviewers',  
            //rules:              'Rules',
            save:               'Salvar',  
            scientia_magazine:  'Scientia Magazine',
            search:             'Search',
            search_by_article_name:             'Search by article name', 
            search_by_author_name:             'Search by author name', 
            search_by_article_keyword:             'Search by keywords', 
            search_error_message:             'Fill in one of the search fields.',
            search_error_noresults_message:             'No results found.',
            seminars:            'Seminars',
            send:               'Send',
            sent_successfully:  'Sent successfully',
            seo_config:         'SEO config',
            seo_description:    'SEO description',
            seo_description_placeholder: 'Insert the SEO description',
            seo_keywords:       'SEO keywords',
            seo_keywords_small: '(separate by comma)',
            seo_keywords_placeholder: 'Insert the SEO keywords',
            seo_title:          'SEO title',
            seo_title_placeholder: 'Insert the SEO title',
            slide_title:        'Together we share knowledge',
            start:              'Start',
            subject:            'Subject',
            submission:         'Submission',
            submission_rules:   'Submission rules',
            submission_rules_pdf:   'Download the instructions',
            submission_rules_pdf_view:   'See submission rules',
            submit:             'Submit',
            submit_article:     'Submit article',
            surname:            'Surname',
            table_rows_per_page:'Regist by page:',
            table_separator:    'of',
            table_all:          'All',
            table_title:        'Title',
            table_parent:       'Parent',
            table_date:         'Date',
            table_state:        'State',
            table_actions:      'Actions',
            text:               'Text',
            title:              'Title',
            title_placeholder:  'Insert title',
            terms_conditions:   'Terms and conditions',
            terms_conditions_link:   'terms and conditions.',
            terms_conditions_required: 'The fields marked with (*) are required. The email address allows for faster contact in the event of further clarification. The information you submit will not be used or distributed for any other reason other than the processing of your request.',
            testimonials:       'Testimonials',
            technical_team:     'Technical team',
            user:               'User',
            username_error_message:  'There is already a record with this username.',
            web_page:           'Web page',
            webinar:            'Webinar',
            workshop:           'Workshop',
            keep_reading:       'Keep reading',
            know_more:       'Know more',
            atividade_1:        'Consultoria e Mentoring',
            atividade_1_txt:    '',
            atividade_2:        'Webinars e Workshops',
            atividade_2_txt:    '',
            atividade_3:        'Seminários e Conferências',
            atividade_3_txt:    '',
            atividade_4:        'Estudos e Manuais de Apoio',
            atividade_4_txt:    '',
            atividade_5:        'Divulgação de Resultados',
            atividade_5_txt:    '',

        }
}

module.exports = {
    language
};
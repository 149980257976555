import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './Navbar.css';

import { language } from '../WEBContent/Language';

import { reqGET, set_language, repo_logo_link } from '../../../Utils';

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Hosts from "../../../Hosts";

import jwt_decode from "jwt-decode";

/* import { Link, animateScroll as scroll } from "react-scroll"; */
import { Link, scroller } from "react-scroll";

import { Link as Link2} from 'react-router-dom';

const getTokenInfo = () => {

    const tokenString = localStorage.getItem('token');
    //console.log(tokenString)
    if (tokenString !== null)
        return jwt_decode(JSON.parse(tokenString).token);
    else
        return null
};

const Navbar = (props) => {

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }

    const [navbar, set_navbar] = useState(data_interface);
    /*     const [navbar_active, set_navbar_active] = useState(false); */

    const [lang, set_lang] = useState('pt');

    const [login_name,] = useState(getTokenInfo() === null ? '' : getTokenInfo().name);


    //const page_location = useLocation().pathname.split('/')[2];

    const page_location = useLocation().pathname.split('/').pop();
    const parent_location = useLocation().pathname.split('/')[1];

    //console.log('teste: ', page_location)

    const change_lang = (l) => {
        set_language(l)
        set_lang(l)
        window.location.reload()

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)
    }

    function do_logout() {
        localStorage.clear();
        window.location.reload()
    }

    let getInfo = async () => {

        /*reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/banner`)
            .then(res => { 
                setBanner(res.data)
                //console.log(res.data)
                setLoading(false)
                //calculo_margem()
            })
            .catch(erro => console.log('Erro', erro))*/

        reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
            .then(res => {
                res.data.forEach((v) => {
                    if (v.info.principal === true) {
                        //set_dados(dados.current = v);
                        set_navbar(v);
                    }
                });

            })
            .catch(erro => console.log('ERROR', erro))
    }

    useEffect(() => {

        set_navbar({ id: '', info: '', content: { childs: [] } })
        getInfo()

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            change_lang('pt')
        }
        else {
            set_lang(localStorage.getItem('lang'))
        }

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)


    }, [])

    return (

        /*<div className="sticky-top">*/
        <div className="fixed-top">
            <section id="navbar">

                <nav className="navbar navbar-expand-lg navbar-light py-0 px-0 nav-effect">
                    <div className="col-12">
                        <div className="row">
                            <div className="top w-100 d-none bg-color-gray-light px-3 py-2 small">
                                <div className="float-left">
                                    <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_top' : '')} onClick={() => change_lang('pt')}>PT</button>
                                    &#124;
                                    <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_top' : '')} onClick={() => change_lang('en')}>EN</button>
                                </div>
                                <div className="float-right">
                                    <a className={(login_name !== '' ? 'd-none ' : '') + (page_location === 'form_author' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'form_author'}>{language[lang].regist}</a>
                                    <span className={login_name !== '' ? 'd-none' : 'px-2'}>&#124;</span>

                                    <a href={Hosts.WEB_BACKOFFICE_URI} target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon className={"font-weight-small " + (login_name !== '' ? '' : 'd-none')} icon={faUser} fixedWidth />
                                        {login_name !== '' ? login_name : language[lang].login}
                                    </a>
                                    <span className={login_name !== '' ? 'px-2' : 'd-none'}>&#124;</span>
                                    <button className={"button_no_border p-0"} onClick={() => do_logout()}>
                                        {login_name !== '' ? 'Logout' : ''}
                                    </button>

                                </div>
                            </div>

                            <div className="col-12 d-none">
                                <div className="row text-center border-bottom">
                                    <a className="navbar-brand pt-0 pb-0 mr-auto ml-auto " href={Hosts.WEB_SITE_URI} alt="">
                                        <img id="big-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="my-4 svg-logo img-fluid" />
                                    </a>
                                </div>
                            </div>


                            <div className="col-12 d-lg-none bg-color-white py-3 hamburguer">
                                <a className="navbar-brand" href={Hosts.WEB_SITE_URI}>
                                    <img id="small-logo" src={repo_logo_link("logo-mobile.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="svg-logo-small img-fluid" />
                                </a>
                                {
                                    //(page_location !== 'form' && page_location !== 'atividades' && parent_location !== 'webinar' && parent_location !== 'workshop') ?
                                        <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                    //:
                                        /*<a href={Hosts.WEB_SITE_URI} className={"float-right"}>
                                            <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                            {language[lang].backhome}
                                        </a>*/
                                }
                            </div>

                            <div className="col-12 collapse navbar-collapse" id="navbarNav">
                                <a className="navbar-brand pt-0 pb-0 pl-3 d-none d-lg-block" href={Hosts.WEB_SITE_URI} alt="">
                                    <img id="big-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="my-4 svg-logo img-fluid" />
                                </a>
                                <div className="top w-100 d-none bg-color-gray-light px-4 py-2">
                                    {/* <div className="float-left">
                                        <ul className="list-inline m-0">
                                            <li className="list-inline-item pr-3">
                                                <a href="http://www.chcbeira.min-saude.pt/" target="_blank" rel="noreferrer">
                                                    <img className="img-fluid" src="./img/logo_chcb.svg" width="180px" alt="Logo CHCB" title="Logo CHCB"/>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="https://www.sns.gov.pt/" target="_blank" rel="noreferrer">
                                                    <img className="img-fluid" src="./img/logo_sns.svg" width="180px" alt="Logo SNS" title="Logo SNS"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                    {/* <div className="float-left "> */}
                                    <div className="float-left d-none">
                                        <button className="pr-2 button_no_border" onClick={() => change_lang('en')}>EN</button>
                                        |
                                        <button className="pl-2 button_no_border" onClick={() => change_lang('pt')}>PT</button>
                                    </div>
                                    <div className="text-right">
                                        <a className={"pr-2 " + (page_location === 'form_author' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'form_author'}>{language[lang].regist}</a>
                                        |
                                        <a className="pl-2" href={Hosts.WEB_BACKOFFICE_URI} target="_blank" rel="noreferrer">{language[lang].login}</a>
                                    </div>
                                </div>
                                <ul className="navbar-nav ml-auto align-items-center">
                                    {/*console.log('RES DATA MENU', navbar)*/}

                                    {props.mostrar_botao_voltar && 0 ?

                                        <li className="nav-item d-none d-lg-block" /* key={key} */ >
                                            <a href={Hosts.WEB_SITE_URI} className={"nav-link "}>
                                                <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                {language[lang].backhome}
                                            </a>
                                        </li>

                                        :

                                        navbar.content.childs.map((v, key) => {

                                            //console.log(v.link)
                                            //console.log(parent_location)
                                            if (v.link === 'form' || v.link === 'concurso_resultados' || v.link === 'atividades') {
                                                return (
                                                    <li className="nav-item" key={key} >
                                                        <a href={v.link} className={"nav-link " + (page_location === v.link ? 'current_top' : '')}>
                                                            {v.title}
                                                        </a>
                                                    </li>
                                                )
                                            }
                                            return (
                                                <li className="nav-item" key={key}>
                                                    {
                                                        (page_location !== 'form' && page_location !== 'concurso_resultados' && page_location !== 'atividades' && parent_location !== 'webinar' && parent_location !== 'workshop' && parent_location !== 'seminar' && parent_location !== 'conference' && parent_location !== 'terms_conditions' && parent_location !== 'pag') ?

                                                            <Link activeClass="current_top" to={v.link} spy={true} smooth={true} offset={-150} duration={600}
                                                                className={"nav-link " /*+ (page_location === 'scientia_magazine' ? 'current_top' : '')*/}>
                                                                {v.title}
                                                            </Link>

                                                        :
                                                            <Link2 
                                                                onClick={() => setTimeout(()=>{scroller.scrollTo(v.link, {activeClass:"current_top", spy: true, smooth: true, offset: -150, duration: 800})}, 700)}
                                                                to="/"
                                                                className={"nav-link "}
                                                                //replace
                                                                >
                                                                    {v.title}
                                                            </Link2>
                                                    }
                                                </li>
                                            )
                                        })
                                    }

                                    <div className="d-none">
                                        <li className="nav-item">
                                            <a className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'scientia_magazine'}>{language[lang].scientia_magazine}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + (page_location === 'rules' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'rules'}>{language[lang].submission_rules}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + (page_location === 'submissions' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'submissions'}>{language[lang].articles_submission}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + (page_location === 'search' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'search'}>{language[lang].search}</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className={"nav-link " + (page_location ===  'reviewers' ? 'current_top': '')} href={Hosts.WEB_SITE_URI + 'reviewers'}>Revisores</a>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <a className={"nav-link " + (page_location ===  'technical_team' ? 'current_top': '')} href={Hosts.WEB_SITE_URI + 'technical_team'}>{language[lang].technical_team}</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className={"nav-link " + (page_location === 'contacts' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'contacts'}>{language[lang].contacts}</a>
                                        </li>
                                    </div>
                                </ul>

                                {/*<a href={Hosts.WEB_SITE_URI + 'form_author'} className="btn btn-primary">Registo</a>*/}
                                {/*<a href={Hosts.WEB_SITE_URI + 'form_reviewer'} className="btn btn-primary">Revisor</a>*/}
                                <div className="overlay"></div>
                            </div>


                        </div>
                    </div>
                </nav>
            </section >
        </div >
    )
};
export default Navbar;